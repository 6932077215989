import React from 'react';
import ListPage from '../../base/ListPage.js';
import APILXD from '../../../http/APILXD.js';
import HttpTool from "../../../tool/HttpTool";
import TimeHelp from "../../../tool/TimeHelp";
import ModalBase from "../../../components/modalBase/index";
import {Button, message, Popover, Tooltip, Tag} from "antd";
import less from "../accountManage/AccountManage.less";
import ModalDel from '../../../components/modalDelete/ModalDelete.js';

const ModalDelete = new ModalDel();
const MB = new ModalBase();

class DashboardThemeList extends ListPage {
    constructor(props) {
        super(props);
    }

    base_getListConfig() {
        return {
            table: {
                columns: this.getTableColumns(),
                url: APILXD.getThemeList,
            },
            new: {
                defaultValue: '新增主题',
                action: () => {
                    this.add();
                }
            },
        }
    }

    getAddData(data) {
        let defaultOption = {
            type: "input",
            ver: true,
            reg: /^[\S\s]{0,50}$/,
            verMessage: "最多50个字符",
            required: false,
        };
        let props = {
            colCount: 1,
            formItemLayout: {
                labelCol: {span: 4},
                wrapperCol: {span: 16, offset: 0},
            },
            parameterArr: [
                {
                    ...defaultOption,
                    field: "title",
                    name: "主题名称",
                    type: 'input',
                    reg: (v) => {
                        return !!v;
                    },
                    verMessage: "1-20个字符",
                    option: {
                        placeholder: "请填写主题名称",
                        maxLength: "20",
                        defaultValue: data?data.title:undefined
                    }
                },
                {
                    ...defaultOption,
                    field: "dataStr",
                    name: "配置内容",
                    type: 'TextArea',
                    verMessage: "请填写正确格式的配置对象",
                    reg: (v) => {

                        return !!v;
                    },
                    option: {
                        maxLength: 10000,
                        placeholder: "请填写配置对象",
                        defaultValue: data?JSON.stringify(data.data):null,
                        autoSize: {
                            minRows: 6,
                            maxRows: 10
                        }
                    }
                },
            ],
        };
        return {
            props,
            otherParam: null
        }
    }

    add(data){
        let addData = this.getAddData(data);
        MB.show(
            {
                title: data?"编辑主题":"添加主题",
                okTitle: "提交",
                closeTitle: "取消",
            },
            addData.props
            ,
            {
                url: data?APILXD.editTheme:APILXD.addTheme,
                otherParam: {
                    _id: data?data._id: null
                },
                beforeSubmit: (param) => {

                    try {
                        let o;
                        let code = `o = ` + param.dataStr;
                        eval(code);
                        console.log(o);
                        if(!o || typeof o !== "object"){
                            message.warning("配置内容格式不正确");
                            return false
                        }
                        param.data= o
                    }catch (e){
                        console.error(e)
                        message.warning("主题出错");
                        return false
                    }


                    //文件地址参数暂时没用到，传空数组
                    // param.files = this.collectFileUrls(param.data);
                    param.files = [];
                    return true;
                },
                callBack: (state, msg) => {
                    //添加成功回调
                    state == 'success' && this._loadDataForNet();
                }
            });
    }

    changeSort(data){
        MB.show(
            {
                title: "修改排序",
                okTitle: "提交",
                closeTitle: "取消",
            },
            {
                colCount: 1,
                formItemLayout: {
                    labelCol: {span: 6},
                    wrapperCol: {span: 12, offset: 0},
                },
                parameterArr: [
                    {
                        ver: true,
                        field: "sort",
                        name: "新的排序",
                        type: 'inputNumber',
                        verMessage: "请填写",
                        required: true,
                        reg: (v) => {
                            return v>0;
                        },
                        option: {
                            defaultValue: data?data.sort:undefined
                        }
                    },
                ],
            }
            ,
            {
                url: APILXD.setThemeSort,
                otherParam: {
                    _id: data._id
                },
                beforeSubmit: (param) => {
                    return true;
                },
                callBack: (state, msg) => {
                    //添加成功回调
                    state == 'success' && this._loadDataForNet();
                }
            });
    }

    /**
     *  列表数据配置
     */
    getTableColumns() {
        return [
            {
                title: '主题名称',
                dataIndex: 'title',
                key: 'title',
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render: (text, record, index) => {
                    return <div>
                        {text===1?<Tag color={"limegreen"}>启用</Tag>:<Tag color={"red"}>禁用</Tag>}
                        {
                            this.powerConfig.Edit
                                ?<span
                                    className={less.btnEdit}
                                    onClick={()=>{
                                        HttpTool.post(APILXD.setThemeStatus,(code,msg,json)=>{
                                            message.success(msg);
                                            this._loadDataForNet()
                                        },(code,msg)=>{
                                            message.error(msg);
                                        }, {
                                            _id: record._id,
                                            status: text===1?2:1
                                        });
                                    }}
                                >
                                        切换
                                </span>
                                :null
                        }
                    </div>
                }
            },
            {
                title: '排序编号',
                dataIndex: 'sort',
                key: 'sort',
                render: (text, record, index) => {
                    return <div>
                        {text}
                        {
                            this.powerConfig.Edit
                                ?<span
                                    className={less.btnEdit}
                                    onClick={()=>{
                                        this.changeSort(record)
                                    }}
                                >
                                        修改
                                </span>
                                :null
                        }
                    </div>
                }
            },
            {
                title: '创建/更新时间',
                dataIndex: 'updatetime',
                key: 'updatetime',
                render: (text, record, index) => {
                    return  <div>{record.updatetime ? TimeHelp.getYMDHM(record.updatetime) : "-"}</div>
                }
            },
            {
                title: '更新者',
                dataIndex: 'updater',
                key: 'updater',
                render: (text, record, index) => {
                    return  text?text.nickname:"-"
                }
            },
            {
                title: '操作',
                width: 120,
                render: (text, record, index) => {
                    return (
                        <div>
                            {
                                this.powerConfig.Edit
                                    ?(  <div
                                        className={less.btnEdit}
                                        onClick={() => {
                                            HttpTool.post(APILXD.getThemeDetail,(code,msg,json)=>{
                                                this.add(json)
                                            },(code,msg)=>{
                                                message.error(msg);
                                            }, {
                                                _id: record._id
                                            });

                                        }}
                                    >编辑
                                    </div>)
                                    :null
                            }
                            {
                                this.powerConfig.Delete
                                    ?(  <div
                                        className={less.btnDelete}
                                        onClick={() => {
                                            ModalDelete.show({
                                                    title: "提示",
                                                    okTitle: "确定",
                                                    closeTitle: "取消",
                                                },
                                                {}
                                                ,
                                                {
                                                    otherParam: {_id: record._id},
                                                    content: record.title,
                                                    url: APILXD.delTheme,
                                                    apiType: 'post',
                                                    tip: '删除后不可恢复',
                                                    callBack: (state) => {
                                                        //删除成功回调
                                                        state === "success" && this._loadDataForNet();
                                                    }
                                                });
                                        }}
                                    >删除
                                    </div>)
                                    :null
                            }
                        </div>)
                }
            },
        ]
    }

    //收集配置文件中的文件地址
    collectFileUrls(themeConfig){
        let fileUrls = [];
        if(!themeConfig){
            return fileUrls;
        }
        let dealAction = (data)=>{
            let prototypeStr = Object.prototype.toString.call(data);
            if(prototypeStr === '[object Object]'){
                //对象，提取键
                Object.keys(data).forEach((eachKey)=>{
                    if(eachKey.indexOf("upload_") === 0 && typeof data[eachKey] === "string"){
                        //是文件地址
                        fileUrls.push(data[eachKey])
                    }else{
                        dealAction(data[eachKey])
                    }
                })
            }else if(prototypeStr === '[object Array]'){
                //数组
                data.forEach((eachItem)=>{
                    dealAction(eachItem)
                })
            }else{
                //其它，不处理
            }
        }

        dealAction(themeConfig);

        return fileUrls;
    }
}

module.exports = DashboardThemeList;