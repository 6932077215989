import React from 'react';
import ListPage from '../../base/ListPage.js';
import less from './ApplicationManage.less';
import APILXD from '../../../http/APILXD.js';
import ModalDel from '../../../components/modalDelete/ModalDelete.js';
import ModalBase from '../../../components/modalBase/index.js';
import {message} from "antd";

const ModalDelete = new ModalDel();
const MB = new ModalBase();

class ApplicationList extends ListPage {
    constructor(props) {
        super(props);
    }

    base_getListConfig() {
        return {
            table: {
                columns: this.getTableColumns(),
                url: APILXD.getAppsList,
                otherParam: {},
            },
            new: {
                defaultValue: '+新增应用',
                action: () => {
                    this.addApplication();
                }
            }
        }
    }

    addApplication(data) {
        let addData = this.getConfig(data);
        MB.show(
            {
                title: data ? "编辑应用" : "新增应用",
                okTitle: data ? "保存" : "添加",
                closeTitle: "取消",
            },
            addData.props
            ,
            {
                otherParam: data ? {
                    _id: data._id
                } : {},
                url: data ? APILXD.editApp : APILXD.addApp,
                beforeSubmit: (params) => {
                    params.name = _.trim(params.name);
                    return true;
                },
                callBack: (state) => {
                    //添加成功回调
                    this._loadDataForNet();
                }
            });
    }

    getConfig(data) {
        let defaultOption = {
            type: "input",
            ver: true,
            reg: /^[\s\S]{1,32}$/,
            verMessage: "请输入，1-32位",
            required: true,
        };
        let props = {
            colCount: 1,
            formItemLayout: {
                labelCol: {span: 5},
                wrapperCol: {span: 14, offset: 0},
            },
            parameterArr: [
                {
                    ...defaultOption,
                    field: "name",
                    name: "应用名称",
                    verMessage: "请输入",
                    option: {
                        maxLength: '32',
                        defaultValue: data ? data.name : ''
                    }
                },
                {
                    ...defaultOption,
                    field: "mark",
                    name: "应用唯一标识",
                    verMessage: "请输入应用唯一标识",
                    reg: /^[a-zA-Z0-9]{1,64}$/,
                    option: {
                        disabled: data ? true : false,
                        maxLength: '64',
                        defaultValue: data ? data.mark : ''
                    }
                },
            ],
        };
        return {
            props,
            otherParam: null
        }
    }

    /**
     *  列表数据配置
     */
    getTableColumns() {
        log(this.powerConfig);
        return [
            {
                title: '应用名',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '唯一标识',
                dataIndex: 'mark',
                key: 'mark',
            },
            {
                title: <div className={less.jumpTitle}>子列表</div>,
                width: 120,
                render: (text, record, index) => {
                    return (<div>
                        <div
                            className={less.btnEdit}
                            onClick={() => {
                                this.props.openTab({
                                    title: '版本列表',
                                    path: 'VersionList',
                                    post: {
                                        appid: record._id,
                                        data: record,
                                        parent: this.props,
                                        navPath: 'PackageAndroid'
                                    }
                                })
                            }}
                        >版本列表
                        </div>
                    </div>)
                }
            },
            {
                title: '操作',
                width: 120,
                render: (text, record, index) => {
                    return (<div>
                        {
                            this.powerConfig.Edit
                                ?<div
                                    className={less.btnEdit}
                                    onClick={() => {
                                        this.addApplication(record);
                                    }}
                                >编辑
                                </div>
                                :null
                        }
                        {
                            this.powerConfig.Delete
                                ?<div
                                    className={less.btnDelete}
                                    onClick={() => {
                                        this.deleteApp(record);
                                    }}
                                >删除
                                </div>
                                :null
                        }

                    </div>)
                }
            }
        ]
    }

    deleteApp(record) {
        ModalDelete.show({
                title: "提示",
                okTitle: "确定",
                closeTitle: "取消",
            },
            {}
            ,
            {
                otherParam: {_id: record._id},
                content: record.name,
                url: APILXD.delApp,
                apiType: 'post',
                tip: '删除后不可恢复',
                callBack: (state) => {
                    //删除成功回调
                    state === "success" && this._loadDataForNet();
                }
            });
    }
}

module.exports = ApplicationList;