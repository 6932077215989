import React from 'react';
import ListPage from '../../base/ListPage.js';
import less from './AccountManage.less';
import APILXD from '../../../http/APILXD.js';
import ModalDel from '../../../components/modalDelete/ModalDelete.js';
import {Modal,message,Tag,Popover} from 'antd';
import HttpTool from "../../../tool/HttpTool";
import TimeHelp from "../../../tool/TimeHelp";
import ModalBase from "../../../components/modalBase/index";

const MB = new ModalBase();
const ModalDelete = new ModalDel();

class AccountList extends ListPage {
    constructor(props) {
        super(props);
    }

    base_getListConfig() {
        return {
            table: {
                columns: this.getTableColumns(),
                url: APILXD.getAdminList,
                otherParam: null,
            },
            search: {
                seniorSearch: this.getSeniorSearch(),
                option: {
                    openSenior: true,
                    placeholder: '输入关键词搜索',
                    reg: /^[\s\S]*$/,
                    beforeSubmit: (value) => {
                        value.role = value.role?Number(value.role):null
                        return true;
                    }
                }
            },
            new: {
                defaultValue: '创建新账号',
                action: () => {
                    this.props.openTab({
                        path: 'AddAccount',
                        title: '新建账号',
                        post: {
                            parent: this.props
                        }
                    })
                }
            }
        }
    }

    getSeniorSearch(defaultData) {
        let defaultOption = {
            type: "input",
            ver: true,
            reg: /^[\S\s]{0,50}$/,
            verMessage: "最多50个字符",
            required: false,
        };
        return {
            colCount: 3,
            formItemLayout: {
                labelCol: {span: 6},
                wrapperCol: {span: 18, offset: 0},
            },
            parameterArr: [
                {
                    ...defaultOption,
                    field: "role",
                    name: "角色",
                    type: 'select',
                    selectType: 'value',
                    reg: (v) => {
                        return true;
                    },
                    option: {
                        defaultValue: {
                            key: "",
                            value: "",
                        },
                    },
                    data: [
                        {
                            title: '全部角色',
                            value: "",
                        },
                        {
                            title: "超级管理员",
                            value: "1",
                        },
                        {
                            title: "普通管理员",
                            value: "2",
                        },
                    ]
                },
            ],
        }
    }

    changeAccountStatus(id,status){
        let param = {
            _id:id,
            status,
        };
        let successCB = (code,msg,json,option)=>{
            this.setState({loading:false},()=>{
                message.success('操作成功');
                this._loadDataForNet();
            });
        };
        let failureCB = (code,msg)=>{
            this.setState({loading:false},()=>{
                message.error(msg);
            });
        };

        this.setState({loading:true});

        HttpTool.post(APILXD.enableAdmin,successCB,failureCB,param);
    }


    /**
     *  列表数据配置
     */
    getTableColumns() {
        return [
            {
                title: '账号',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '角色',
                dataIndex: 'role',
                key: 'role',
                render:(text)=>{
                    return text==1?<Tag color={"dodgerblue"}>超级管理员</Tag>:<Tag>普通管理员</Tag>
                }
            },
            {
                title: '授权Rom',
                dataIndex: 'romIds',
                key: 'romIds',
                width: 400,
                render:(text,record)=>{
                    return record.role === 1
                      ?<span style={{color: "limegreen"}}>全部</span>
                      :(
                        (text && text.length)
                          ? text.join(",")
                          :<span style={{color: "red"}}>无</span>
                      )

                }
            },
            {
                title: '登录/注册信息',
                dataIndex: 'logintime',
                key: 'logintime',
                render:(text,record)=>{
                    return <Popover
                      title={"登录/注册信息"}
                      content={<div>
                          <div>注册时间：{TimeHelp.getYMDHM(record.addtime,true)}</div>
                          <div>最后登录时间：{text?TimeHelp.getYMDHM(text,true):"-"}</div>
                          <div>最后登录ip：{record.ip || "-"}</div>
                      </div>}
                    >
                        查看
                    </Popover>
                }
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render:(text)=>{
                    return text==1?<span style={{color:'green'}}>启用</span>:<span style={{color:'red'}}>禁用</span>
                }
            },
            {
                title: '操作',
                width: 250,
                render: (text, record, index) => {
                    return (<div>
                        {
                            this.powerConfig.Edit
                                ?(  <div
                                    className={record.status==1?less.btnDelete:less.btnEdit}
                                    onClick={() => {
                                        let newStatus = record.status==1?2:1;
                                        Modal.confirm({
                                            title:'提示',
                                            content:<div>
                                                <span>是否 </span>
                                                {record.status==1?<span style={{color:'red'}}>禁用</span>:<span style={{color:'green'}}>启用</span>}
                                                <span> {record.name} ?</span>
                                            </div>,
                                            onOk:()=>{this.changeAccountStatus(record._id,newStatus)}
                                        })
                                    }}
                                >{record.status==1?'禁用':'启用'}
                                </div>)
                                :null
                        }
                        {
                            this.powerConfig.Edit
                                ?(  <div
                                    className={less.btnEdit}
                                    onClick={() => {
                                        this.props.openTab({
                                            path: 'AddAccount',
                                            title: '编辑账号',
                                            post: {
                                                id: record._id,
                                                parent: this.props
                                            }
                                        })
                                    }}
                                >编辑
                                </div>)
                                :null
                        }
                        {
                            record.role === 2 && this.powerConfig.Edit
                              ?(  <div
                                className={less.btnEdit}
                                onClick={() => {
                                    if(record.role === 1){
                                        message.info('超级管理员无需授权');
                                    }else{
                                        this.selRoms(record)
                                    }
                                }}
                              >Rom授权
                              </div>)
                              :null
                        }
                        {
                            this.powerConfig.Delete
                            ?(<div
                                    className={less.btnDelete}
                                    onClick={() => {
                                        ModalDelete.show({
                                                title: "提示",
                                                okTitle: "确定",
                                                closeTitle: "取消",
                                            },
                                            {}
                                            ,
                                            {
                                                otherParam: {_id: record._id},
                                                content: record.name,
                                                url: APILXD.delAdmin,
                                                apiType: 'post',
                                                tip: '删除后不可恢复',
                                                callBack: (state) => {
                                                    //删除成功回调
                                                    state === "success" && this._loadDataForNet();
                                                }
                                            });
                                    }}
                                >删除
                                </div>)
                            :null
                        }
                    </div>)
                }
            },
        ]
    }

    selRoms(data){
        MB.show(
          {
              title: "授权Rom",
              okTitle: "确定",
              closeTitle: "取消",
          },
          {
              colCount: 1,
              formItemLayout: {
                  labelCol: {span: 5},
                  wrapperCol: {span: 14, offset: 0},
              },
              parameterArr: [
                  {
                      field: "name",
                      name: "账号",
                      option: {
                          defaultValue: data ? data.name : ''
                      }
                  },
                  {
                      field: "romIds",
                      name: "选择Rom",
                      verMessage: "请选择",
                      type: 'selectAll',
                      selectType: 'value',
                      apiConfig: {
                          url: APILXD.getHeartBeatRomIds,
                          fillObject: (json) => {
                              let data = [];
                              for (let obj of json) {
                                  data.push({
                                      title: obj,
                                      value: obj,
                                  });
                              }
                              return data;
                          }
                      },
                      data: [],
                      option: {
                          allowClear: true,
                          dropdownMatchSelectWidth: false,
                          showSearch: true,
                          onSearch: ()=>{},
                          filterOption: (input, option) => {
                              return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          },
                          optionFilterProp: "children",
                          mode: "multiple",
                          defaultValue: (data && data.romIds) ? data.romIds.map((s)=>{
                              return {
                                  key: s,
                                  value: s
                              }
                          }) : []
                      }
                  },
              ],
          },
          {
              url: APILXD.authRomIds,
              beforeSubmit: (param) => {
                  param._id = data._id;
                  param.romIds = param.romIds.map((o)=>{
                      return typeof o === "string"?o:o.key
                  })
                  return true;
              },
              callBack: (state, msg) => {
                  //添加成功回调
                  state == 'success' && this._loadDataForNet();
              }
          });
    }
}

export default AccountList;