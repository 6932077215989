import React from 'react'
import { Route,Routes } from "react-router-dom";

import App from './main/App.js';
let routes = (<Routes>
    <Route path="/" element={<App />}/>
</Routes>);

export default routes;

