import React from 'react';
import TablePageBase from "../../base/ListPage";
import TimeHelp from '../../../tool/TimeHelp';
import APILXD from "../../../http/APILXD";
import ModalDel from '../../../components/modalDelete/ModalDelete.js';
import less from "../../navigationPages/accountManage/AccountManage.less";
import ModalBase from "../../../components/modalBase/index";
import {Button} from "antd";

const ModalDelete = new ModalDel()
const MB = new ModalBase();

export default class ShardDelDeviceConfig extends TablePageBase {
  constructor(props) {
    super(props);
  }

  base_getListConfig() {
    let defaultData = (this.props && this.props.post) || {};
    return {
      table: {
        couldSelect: true,
        title: "删除指定分身的设备白名单",
        columns: this.getTableColumns(),
        url: APILXD.shardDelDeviceGetDeviceList,
        otherParam: {},
        option: {}
      },
      search: {
        seniorSearch: this.getSeniorSearch(),
        option: {
          placeholder: "请输入设备id搜索",
          reg: /^[\s\S]*$/,
          beforeSubmit: (value) => {
            value.deviceId = value.keyword
            return true;
          }
        }
      },
      new: {
        defaultValue: '批量添加需要删除分身的设备',
        action: () => {
          this.add();
        }
      },
      extraBox: ()=>{
        return <div style={{float:"right"}}>
          <Button
            type={"primary"}
            style={{marginLeft: "20px"}}
            disabled={!(this.state.selectedRowKeys && this.state.selectedRowKeys.length)}
            size={"large"}
            onClick={()=>{
              console.log(this.state.selectedRowKeys)
              this.add({
                deviceIds: this.state.selectedRowKeys,
              })
            }}
          >
            批量编辑
          </Button>
          <Button
            type={"primary"}
            danger={true}
            size={"large"}
            style={{marginLeft: "20px"}}
            disabled={!(this.state.selectedRowKeys && this.state.selectedRowKeys.length)}
            onClick={()=>{
              console.log(this.state.selectedRowKeys)
              ModalDelete.show({
                  title: "提示",
                  okTitle: "确定",
                  closeTitle: "取消",
                },
                {}
                ,
                {
                  otherParam: {deviceIds: this.state.selectedRowKeys},
                  content: `勾选的${this.state.selectedRowKeys.length}个设备配置`,
                  url: APILXD.shardDelDeviceDelDevice,
                  apiType: 'post',
                  callBack: (state) => {
                    //删除成功回调
                    state === "success" && this._loadDataForNet();
                  }
                });
            }}
          >
            批量删除
          </Button>
        </div>
      }
    }
  }

  getSeniorSearch() {
    return {
      colCount: 3,
      formItemLayout: {
        labelCol: {span: 6},
        wrapperCol: {span: 18, offset: 0},
      },
      parameterArr: [
        {
          field: "shard",
          name: "分身",
          type: 'select',
          selectType: 'value',
          reg: (v) => {
            return !!v;
          },
          apiConfig: {
            url: APILXD.getBoxShard,
            fillObject: (json) => {
              let data = [];
              for (let obj of json) {
                data.push({
                  title: `${obj.descs}(${obj.shard})`,
                  value: obj.shard,
                });
              }

              return data;
            }
          },
          option: {
            dropdownMatchSelectWidth: false,
            showSearch: true,
            allowClear: true,
            placeholder: "输入分身匹配",
            onSearch: () => {},
            filterOption: (input, option) => {
              return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            optionFilterProp: "children",
          },
          data: []
        },
      ],
    }
  }

  //新增或编辑
  getAddData(data) {
    let defaultOption = {
      type: "input",
      ver: true,
      reg: /^[\s\S]{1,50}$/,
      verMessage: "请输入",
      required: true,
    };
    let props = {
      colCount: 1,
      formItemLayout: {
        labelCol: {span: 4},
        wrapperCol: {span: 18, offset: 0},
      },
      parameterArr: [
        {
          ...defaultOption,
          field: "deviceIdsSet",
          name: '设备配置',
          type: "TextArea",
          purpose: data ? "see" : "edit",
          required: true,
          reg: /^[\s\S]{1,300000}$/,
          option: {
            autosize: {minRows: 4, maxRows: 6},
            maxLength: '300000',
            placeholder: '请输入设备编码，用英文逗号(,)或换行分隔，例如 2131231,sadasdsa 注：空格会自动清除',
            defaultValue: (data && data.deviceIds) ? data.deviceIds.join(",") : ""
          }
        },
        {
          field: "shardsSet",
          name: "分身(可多选)",
          type: 'selectAll',
          selectType: 'value',
          required: true,
          reg: (v) => {
            return v && v.length;
          },
          apiConfig: {
            url: APILXD.getBoxShard,
            fillObject: (json) => {
              let data = [];
              for (let obj of json) {
                data.push({
                  title: `${obj.descs}(${obj.shard})`,
                  value: obj.shard,
                });
              }

              return data;
            }
          },
          option: {
            dropdownMatchSelectWidth: false,
            showSearch: true,
            mode: "multiple",
            allowClear: true,
            placeholder: "输入分身匹配",
            onSearch: () => {},
            filterOption: (input, option) => {
              return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            optionFilterProp: "children",
            defaultValue: (data && data.shards) ? data.shards.map((t) => {
              return {
                key: t,
                value: t
              }
            }) : []
          },
          data: []
        }
      ],
    };
    return {
      props,
      otherParam: null
    }
  }

  add(data) {
    let addData = this.getAddData(data);
    MB.show(
      {
        title: "添加设备与分身删除配置",
        okTitle: data ? "编辑" : "添加",
        closeTitle: "取消",
      },
      addData.props
      ,
      {
        url: APILXD.shardDelDeviceAddDevice,
        beforeSubmit: (param) => {
          param.deviceIds = _.map(param.deviceIdsSet.split(/[,\n]/g), _.trim).filter((data) => {
            return !!data
          })
          param.shards = param.shardsSet.map((item)=>{
            return item.key || item.value || item
          })
          return true;
        },
        callBack: (state, msg) => {
          //添加成功回调
          state == 'success' && this._loadDataForNet();
        }
      });
  }

  /**
   *  列表数据配置
   */
  getTableColumns() {
    return [
      {
        title: '设备id',
        dataIndex: '_id',
        key: '_id',
      },
      {
        title: '需删除分身',
        dataIndex: 'shards',
        key: 'shards',
        render: (text, record) => {
          return record.shards ? record.shards.join(",") : ""
        }
      },
      {
        title: '添加时间',
        dataIndex: 'addtime',
        key: 'addtime',
        render: (text) => {
          return text && TimeHelp.getYMDHM(text + 2000, true)
        }
      },
      {
        title: "操作",
        width: 200,
        render: (text, record) => {
          return <div>
            {
              this.powerConfig.Edit
                ? <div
                  className={less.btnEdit}
                  onClick={() => {
                    this.add({
                      deviceIds: [record._id],
                      shards: record.shards
                    })
                  }}
                >编辑
                </div>
                : null
            }
            {
              this.powerConfig.Delete
                ? <div
                  className={less.btnDelete}
                  onClick={() => {
                    ModalDelete.show({
                        title: "提示",
                        okTitle: "确定",
                        closeTitle: "取消",
                      },
                      {}
                      ,
                      {
                        otherParam: {deviceIds: [record._id]},
                        content: record._id,
                        url: APILXD.shardDelDeviceDelDevice,
                        apiType: 'post',
                        callBack: (state) => {
                          //删除成功回调
                          state === "success" && this._loadDataForNet();
                        }
                      });
                  }}
                >删除
                </div>
                : null
            }
          </div>
        }
      }
    ]
  }
}