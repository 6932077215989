import React from 'react';
import TablePageBase from "../../base/ListPage";
import TimeHelp from '../../../tool/TimeHelp';
import APILXD from "../../../http/APILXD";
import ModalDel from '../../../components/modalDelete/ModalDelete.js';
import { Tooltip, Tag, Popover } from "antd";
import less from "../../navigationPages/accountManage/AccountManage.less";
import ModalBase from '../../../components/modalBase/index.js';

const ModalDelete = new ModalDel()
const MB = new ModalBase();

class IPFSWhiteList extends TablePageBase {
  constructor(props) {
    super(props);
  }

  base_getListConfig() {
    let defaultData = (this.props && this.props.post) || {};
    return {
      table: {
        title:"应用升级IPFS设备白名单",
        columns: this.getTableColumns(),
        url: APILXD.ipfsGetDeviceList,
        otherParam: {},
        option: {}
      },
      search: {
        option: {
          placeholder: "请输入设备id搜索",
          hideInput: false,
          reg: /^[\s\S]*$/,
          beforeSubmit: (value) => {
            value.deviceId = value.keyword
            return true;
          }
        }
      },
      new: {
        defaultValue: '添加设备',
        action: () => {
          this.add();
        }
      },
    }
  }

  getAddData(data) {
    let defaultOption = {
      type: "input",
      ver: true,
      reg: /^[\s\S]{1,50}$/,
      verMessage: "请输入",
      required: true,
    };
    let props = {
      colCount: 1,
      formItemLayout: {
        labelCol: {span: 6},
        wrapperCol: {span: 12, offset: 0},
      },
      parameterArr: [
        {
          ...defaultOption,
          field: "deviceId",
          name: '设备id',
          required: "true",
          option: {
            placeholder: '请输入设备id',
          }
        },
      ],
    };
    return {
      props,
      otherParam: null
    }
  }

  add() {
    let addData = this.getAddData({});
    MB.show(
      {
        title: "添加设备",
        okTitle: "添加",
        closeTitle: "取消",
      },
      addData.props
      ,
      {
        url: APILXD.ipfsAddDevice,
        beforeSubmit: (param) => {
          return true;
        },
        callBack: (state, msg) => {
          //添加成功回调
          state == 'success' && this._loadDataForNet();
        }
      });
  }

  /**
   *  列表数据配置
   */
  getTableColumns() {
    return [
      {
        title: '设备id',
        dataIndex: '_id',
        key: '_id',
      },
      {
        title: '添加时间',
        dataIndex: 'addtime',
        key: 'addtime',
        render: (text) => {
          return text && TimeHelp.getYMDHM(text + 2000, true)
        }
      },
      {
        title: "操作",
        render: (text,record)=>{
          return <div>
            {
              this.powerConfig.Delete
                ?<div
                  className={less.btnDelete}
                  onClick={() => {
                    ModalDelete.show({
                        title: "提示",
                        okTitle: "确定",
                        closeTitle: "取消",
                      },
                      {}
                      ,
                      {
                        otherParam: {deviceId: record._id},
                        content: record._id,
                        url: APILXD.ipfsDelDevice,
                        apiType: 'post',
                        callBack: (state) => {
                          //删除成功回调
                          state === "success" && this._loadDataForNet();
                        }
                      });
                  }}
                >删除
                </div>
                :null
            }
          </div>
        }
      }
    ]
  }
}

export default IPFSWhiteList