import React from 'react';
import less from '../ApplicationManage.less';
import {message} from "antd/lib/index";
import APILXD from "../../../../http/APILXD";
import {Spin, Button, Modal, Row, Col} from 'antd';
import UploadHelp from '../../../../components/upLoad/UploadHelp';
import SparkMD5 from 'spark-md5';
import HttpTool from "../../../../tool/HttpTool";
import Layout from "../../../../components/layout/index";
import _ from "lodash"

class VersionAdd extends React.Component {
    constructor(props) {
        super(props);
        this.upload = new UploadHelp();
        this.params = this.props.post.data;
        this.appid = this.props.post.appid;
        this.state = {
            url: this.params && this.params.url || '',
            md5: this.params && this.params.md5 || '',
            loading: false,
            upDate: 0,
            uploadingMsg: null
        }
    }

    //配置
    getConfig(data) {
        let defaultOption = {
            type: "input",
            ver: true,
            reg: /^[\s\S]{1,32}$/,
            verMessage: "1-32位数字或英文字母",
            required: true,
        };
        return {
            colCount: 1,
            formItemLayout: {
                labelCol: {span: 5},
                wrapperCol: {span: 14, offset: 0},
            },
            parameterArr: [
                {
                    ...defaultOption,
                    field: "version",
                    name: "版本名(versionName)",
                    reg: /^\d+(\.\d+)*$/,
                    verMessage: "版本名的格式应如 x.x.x ",
                    option: {
                        placeholder: '请输入版本名，1-32位，格式如 x.x.x',
                        maxLength: '32',
                        defaultValue: data && data.version || undefined,
                        disabled: this.params ? true : false,
                    }
                },
                {
                    ...defaultOption,
                    field: "descs",
                    name: "备注",
                    type: "TextArea",
                    required: false,
                    reg: /^[\s\S]{0,250}$/,
                    option: {
                        placeholder: '请填写备注，最多250个字符',
                        maxLength: '250',
                        autosize: {minRows: 3, maxRows: 5},
                        defaultValue: data && data.descs || undefined,
                    }
                },
                {
                    ...defaultOption,
                    field: "whiteTypeSet",
                    name: "白名单类型",
                    type: 'Radio',
                    required: true,
                    verMessage: "请选择",
                    reg: (v)=>{
                        return true
                    },
                    option: {
                        defaultValue: data ? ""+data.whiteType : "0"
                    },
                    data: [
                        {title: "无白名单",value: "0"},
                        {title: "设备白名单",value: "1"},
                        {title: "Rom白名单",value: "2"},
                    ],
                    onChange: (value, state, changeState)=>{
                        console.log(value);
                        let newArr = state.parameterArr;
                        newArr.forEach((obj) => {
                            if (obj.mark === 'whitelistDevice') {
                                obj.field = value === "1" ? obj.mark : null;
                            }
                            if (obj.mark === 'whitelistRom') {
                                obj.field = value === "2" ? obj.mark : null;
                            }
                        });
                        changeState('parameterArr', newArr);
                    }
                },
                {
                    ...defaultOption,
                    field: (data && data.whiteType === 1)?"whitelistDevice":null,
                    mark: "whitelistDevice",
                    name: "设备白名单",
                    type: 'TextArea',
                    required: true,
                    verMessage: "仅支持字母、数字、下划线和英文逗号，不能为空",
                    reg: /^[a-zA-Z0-9,_]{1,16777215}$/,
                    option: {
                        style:{backgroundColor:"#7cd08a"},
                        placeholder: '请填写设备白名单，不同设备号用英文逗号隔开，最多16777215个字符',
                        maxLength: '16777215',
                        autosize: {minRows: 6, maxRows: 10},
                        defaultValue: data ? data.whitelist : null
                    }
                },
                {
                    ...defaultOption,
                    field: (data && data.whiteType === 2)?"whitelistRom":null,
                    mark: "whitelistRom",
                    name: "Rom白名单",
                    type: 'selectAll',
                    selectType: 'value',
                    reg: (v) => {
                        return v && v.length;
                    },
                    verMessage: "请选择需要加入白名单的rom",
                    apiConfig: {
                        url: APILXD.getDeviceRomIdList,
                        fillObject: (json) => {
                            let data = [];
                            for (let obj of json) {
                                data.push({
                                    title: obj,
                                    value: obj,
                                });
                            }
                            return data;
                        }
                    },
                    option: {
                        dropdownMatchSelectWidth: false,
                        showSearch: true,
                        onSearch: ()=>{},
                        filterOption: (input, option) => {
                            return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        },
                        optionFilterProp: "children",
                        defaultValue: (data && data.whitelist) ? data.whitelist.split(",").map((t)=>{return {
                            key: t,
                            value: t
                        }}) : []
                    },
                    data: []
                },
            ],
        }
    }

    //点击创建完成按钮
    clickAddBtn() {
        let option = this.baseMsgLayout.getLayoutValue(true);
        if (option.error) {
            message.warning('请完善表单信息');
            return;
        }
        if (!this.state.url) {
            message.warning('请上传文件');
            return;
        }
        if (!this.state.md5) {
            message.warning('md5未计算');
            return;
        }
        let data = option.parameter;

        data.version = data.version.replace(/(^\s*)|(\s*$)/g, "");
        data.url = this.state.url;
        data.md5 = this.state.md5;
        data.status = this.params?Number(this.params.status):0;
        data.descs = data.descs ? data.descs : null;
        data.whiteType = Number(data.whiteTypeSet);
        if(data.whiteType === 1){
            data.whitelist = data.whitelistDevice ? data.whitelistDevice : null;
        }else if(data.whiteType === 2){
            data.whitelist = (data.whitelistRom && data.whitelistRom.length) ? data.whitelistRom.map((t)=>{
                return typeof t === "string"?t:t.value
            }).join(",") : null;
        }else{
            data.whitelist = null
        }

        Modal.confirm({
            title: '提示',
            content: '是否确认提交？',
            onOk: () => {
                this.doSubmit(data);
            }
        })
    }

    //提交数据
    doSubmit(data) {
        let param = _.cloneDeep(data);
        delete param.whitelistRom;
        delete param.whitelistDevice;
        delete param.whiteTypeSet;
        param.appid = this.appid;
        if (this.params) {
            param._id = this.params._id;
        }
        log(param)
        let successCB = (code, msg, json, option) => {
            this.setState({loading: false}, () => {
                message.success('修改成功');
                this.props.openTab(this.props.post.parent);
                this.props.closeTab(this.props.targetKey);
            });
        };
        let failureCB = (code, msg) => {
            this.setState({loading: false}, () => {
                message.error(msg);
            });
        };

        this.setState({loading: true});
        let url = this.params ? APILXD.editAppVersion : APILXD.addAppVersion;
        HttpTool.post(url, successCB, failureCB, param);
    }

    //上传文件
    dealSelFile(selFile) {
        log(selFile);
        let blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice,
            file = selFile,
            chunkSize = 2097152,                             // Read in chunks of 2MB
            chunks = Math.ceil(file.size / chunkSize),
            currentChunk = 0,
            spark = new SparkMD5.ArrayBuffer(),
            fileReader = new FileReader();

        fileReader.onload = (e) => {
            log('read chunk nr', currentChunk + 1, 'of', chunks);
            spark.append(e.target.result);                   // Append array buffer
            currentChunk++;

            if (currentChunk < chunks) {
                loadNext();
            } else {
                log('finished loading');
                this.setState({
                    md5: spark.end(),
                }, () => {
                    this.toUploadFile(selFile);
                })
            }
        };

        fileReader.onerror = function () {
            message.warning("文件解析出错");
        };

        let loadNext = () => {
            let start = currentChunk * chunkSize,
                end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize;

            fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
        };

        loadNext();
    }

    toUploadFile(file) {
        log(file);
        let suffixList = file && file.name && file.name.split('.');
        if (suffixList.length <= 1) {
            message.warning('文件选择出错');
            return;
        }
        let suffix = suffixList[suffixList.length - 1];
        if (!suffix) {
            message.warning('文件选择出错');
            return;
        }
        let successCB = (code, msg, json, option) => {
            this.setState({
                url: json.url,
                uploadingMsg: null,
            })
        };
        let failureCB = (code, msg) => {
            this.setState({
                uploadingMsg: null,
            }, () => {
                message.error('上传失败')
            });
        };
        let loadingCB = (res) => {
            if (res && res.total) {
                this.setState({
                    uploadingMsg: res.total
                })
            }
        };

        this.setState({
            upLoading: true,
        }, () => {
            this.upload.upload(file, suffix, successCB, failureCB, loadingCB);
        });
    }

    render() {
        return (<div className={less.mainPage}>
            <Spin spinning={this.state.loading} size={'large'}>
                <Layout
                    key={'layout' + this.state.upDate}
                    ref={(ref) => {
                        this.baseMsgLayout = ref;
                    }}
                    {
                        ...this.getConfig(this.params)
                    }
                />
                <Row>
                    <Col span={5}>
                        <div className={less.label}>
                            文件下载地址：
                        </div>
                    </Col>
                    <Col span={14}>
                        {
                            this.state.url
                                ? (<div>
                                    {
                                        this.params
                                            ?null
                                            :<div
                                                className={less.btnDelete02}
                                                onClick={() => {
                                                    this.setState({
                                                        url: null,
                                                        md5: null,
                                                    })
                                                }}
                                            >删除
                                            </div>
                                    }
                                    <div>{this.state.url}</div>
                                </div>)
                                : (<div>
                                    {
                                        this.state.uploadingMsg
                                            ? (<div>
                                                <div>
                                                    <span>上传进展：&nbsp;</span>
                                                    <span>{this.state.uploadingMsg.loaded}</span>
                                                    <span>&nbsp;/&nbsp;</span>
                                                    <span>{this.state.uploadingMsg.size}</span>
                                                </div>
                                                <div className={less.progressOut}>
                                                    <div
                                                        style={{
                                                            width: (100 - this.state.uploadingMsg.percent) + '%'
                                                        }}
                                                        className={less.progressIn}
                                                    />
                                                </div>
                                            </div>)
                                            : <input
                                                type="file"
                                                onChange={(e) => {
                                                    let targetFile = e.target.files[0];
                                                    log(targetFile);

                                                    this.dealSelFile(targetFile);
                                                }}
                                            />
                                    }
                                </div>)
                        }
                    </Col>
                </Row>
                <Row style={{marginTop: '20px'}}>
                    <Col span={5}>
                        <div className={less.label}>
                            文件md5：
                        </div>
                    </Col>
                    <Col span={14}>
                        {this.state.md5 || ""}
                    </Col>
                </Row>
                <div
                    className={less.btnBox}
                >
                    <Button
                        size={'large'}
                        className={less.btn}
                        onClick={() => {
                            this.props.openTab(this.props.post.parent);
                            this.props.closeTab(this.props.targetKey);
                        }}
                    >取消</Button>
                    <Button
                        disabled={!this.state.url}
                        type={'primary'}
                        size={'large'}
                        className={less.btn}
                        onClick={() => {
                            this.clickAddBtn();
                        }}
                    >提交</Button>
                </div>
            </Spin>
        </div>)
    }
}

module.exports = VersionAdd;