import pako from "pako";
function Binary(initData, p, l, bl) {
    var data = initData && initData.constructor == Array ? initData.slice() : [],
        p = p | 0,
        l = l | 0,
        bl = Math.max((bl || 8) | 0, 1),
        mask = m(bl),
        _m = 0xFFFFFFFF; //数据，指针，长度，位长度，遮罩
    this.data = function (index, value) {
        if (!isNaN(value)) data[index] = (value | 0) || 0;
        if (!isNaN(index)) return data[index];
        else return data.slice();
    }

    this.read = function () {
        var re;
        if (p >= l) return 0;
        if (32 - (p % 32) < bl) {
            re = (((data[p >> 5] & m(32 - (p % 32))) << ((p + bl) % 32)) | (data[(p >> 5) + 1] >>> (32 - ((p + bl) % 32)))) & mask;
        } else {
            re = (data[p >> 5] >>> (32 - (p + bl) % 32)) & mask;
        }
        p += bl;
        return re;
    }

    this.write = function (i) {
        var i, hi, li;
        i &= mask;
        if (32 - (l % 32) < bl) {
            data[l >> 5] |= i >>> (bl - (32 - (l % 32)));
            data[(l >> 5) + 1] |= (i << (32 - ((l + bl) % 32))) & _m;
        } else {
            data[l >> 5] |= (i << (32 - ((l + bl) % 32))) & _m;
        }
        l += bl;
    }

    this.eof = function () {
        return p >= l;
    }

    this.reset = function () {
        p = 0;
        mask = m(bl);
    }
    this.resetAll = function () {
        data = [];
        p = 0;
        l = 0;
        bl = 8;
        mask = m(bl);
        _m = 0xFFFFFFFF;
    }

    this.setBitLength = function (len) {
        bl = Math.max(len | 0, 1);
        mask = m(bl);
    }

    this.toHexString = function () {
        var re = [];
        for (var i = 0; i < data.length; i++) {
            if (data[i] < 0) {
                re.push(pad((data[i] >>> 16).toString(16), 4) + pad((data[i] & 0xFFFF).toString(16), 4));
            } else {
                re.push(pad(data[i].toString(16), 8));
            }
        }
        return re.join("");
    }

    this.toBinaryString = function () {
        var re = [];
        for (var i = 0; i < data.length; i++) {
            if (data[i] < 0) {
                re.push(pad((data[i] >>> 1).toString(2), 31) + (data[i] & 1));
            } else {
                re.push(pad(data[i].toString(2), 32));
            }
        }
        return re.join("").substring(0, l);
    }

    this.toCString = function () {
        var _p = p,
            _bl = bl,
            re = [];
        this.setBitLength(13);
        this.reset();
        while (p < l) re.push(C(this.read()));
        this.setBitLength(_bl);
        p = _p;
        return C(l >>> 13) + C(l & m(13)) + re.join("");
    }

    this.fromCString = function (str) {
        this.resetAll();
        this.setBitLength(13);
        for (var i = 2; i < str.length; i++) this.write(D(str, i));
        l = (D(str, 0) << 13) | (D(str, 1) & m(13));
        return this;
    }

    this.clone = function () {
        return new Binary(data, p, l, bl);
    }

    function m(len) {
        return (1 << len) - 1;
    }

    function pad(s, len) {
        return (new Array(len + 1)).join("0").substring(s.length) + s;
    }

    function C(i) {
        return String.fromCharCode(i + 0x4e00);
    }

    function D(s, i) {
        return s.charCodeAt(i) - 0x4e00;
    }
}

//压缩
function lzw_compress(str) {


    var b = new Binary(),
        code_index = -1,
        char_len = 8;
    var str = str.replace(/[\u0100-\uFFFF]/g,
        function (s) {
            return "\&\#u" + pad(s.charCodeAt(0).toString(16), 4) + ";";
        });
    var dic = {},
        cp = [],
        cpi,
        bl = 8;
    b.setBitLength(bl);
    for (var i = 0; i < (1 << char_len) + 2; i++) dic[i] = ++code_index;
    cp[0] = str.charCodeAt(0);
    for (var i = 1; i < str.length; i++) {
        cp[1] = str.charCodeAt(i);
        cpi = (cp[0] << 16) | cp[1];
        if (dic[cpi] == undefined) {
            dic[cpi] = (++code_index);
            if (cp[0] > m(bl)) {
                b.write(0x80);
                b.setBitLength(++bl);
            }
            b.write(cp[0]);
            cp[0] = cp[1];
        } else {
            cp[0] = dic[cpi];
        }
    }
    b.write(cp[0]);

    function pad(s, len) {
        return (new Array(len + 1)).join("0").substring(s.length) + s;
    }

    function m(len) {
        return (1 << len) - 1;
    }

    return b.toCString();
}

// 解压
function lzw_decompress(s) {




    var b = new
    function () {
        var d = [],
            p = 0,
            l = 0,
            L = 13,
            k = m(L),
            _m = 0xFFFFFFFF;
        this.r = function () {
            var r;
            if (32 - (p % 32) < L) r = (((d[p >> 5] & m(32 - (p % 32))) << ((p + L) % 32)) | (d[(p >> 5) + 1] >>> (32 - ((p + L) % 32)))) & k;
            else r = (d[p >> 5] >>> (32 - (p + L) % 32)) & k;
            p += L;
            return r;
        };
        this.w = function (i) {
            i &= k;
            if (32 - (l % 32) < L) {
                d[l >> 5] |= i >>> (L - (32 - (l % 32)));
                d[(l >> 5) + 1] |= (i << (32 - ((l + L) % 32))) & _m;
            } else d[l >> 5] |= (i << (32 - ((l + L) % 32))) & _m;
            l += L;
        };
        this.e = function () {
            return p >= l;
        };
        this.l = function (len) {
            L = Math.max(len | 0, 1);
            k = m(L);
        };

        function m(len) {
            return (1 << len) - 1;
        }

        function pad(s, l) {
            return (new Array(l + 1)).join("0").substring(s.length) + s;
        }

        for (var i = 2; i < s.length; i++) this.w(s.charCodeAt(i) - 0x4e00);
        l = ((s.charCodeAt(0) - 0x4e00) << 13) | ((s.charCodeAt(1) - 0x4e00) & m(13));
        p = 0;
    };
    var R = [],
        C = -1,
        D = {},
        P = [],
        L = 8;
    for (var i = 0; i < (1 << L) + 2; i++) D[i] = String.fromCharCode(++C);
    b.l(L);
    P[0] = b.r();
    while (!b.e()) {
        P[1] = b.r();
        if (P[1] == 0x80) {
            b.l(++L);
            P[1] = b.r();
        }
        if (D[P[1]] == undefined) D[++C] = D[P[0]] + D[P[0]].charAt(0);
        else D[++C] = D[P[0]] + D[P[1]].charAt(0);
        R.push(D[P[0]]);
        P[0] = P[1];
    }
    R.push(D[P[0]]);
    let str =  R.join("").replace(/\&\#u[0-9a-fA-F]{4};/g,
        function (w) {
            return String.fromCharCode(parseInt(w.substring(3, 7), 16));
        });

    return str;
}

//解压
let Decompress =  function (str) {
    if(!canDecode(str)){
        //是压缩的文本，进行解压
        //分片解压
        // console.log("___解压前编码："+str.length)
        let str_result = [];
        let str_arr = str.split("__________");
        for(let value of str_arr){

            let v = lzw_decompress(value);
            // console.log("___解压片段："+v.length)
            str_result.push(v);
        }
        str = str_result.join("");
        // console.log("___解压后编码："+str.length)
        // console.log("合并分段后",str)
    }
    // console.log("__",str)
    // str = lzw_decompress(str);
    str = decodeURIComponent(str);


    // str =  Base64.decode(str)
    return str;
};
//压缩
let Compress = function (str,iscompress = true) {
    str = encodeURIComponent(str);
    // console.log(str)
    // str  = Base64.encode(str)
    if(iscompress){
        // console.log("___压缩前编码："+str.length)

        //分片压缩
        let size = str.length
        let ev = 10000;
        let after = size%ev>0?1:0
        let step = Math.floor(size/ev)+after;

        let str_arr = [];
        for(let i=0;i<step;i++){
            let v =  str.substr(ev*i,ev);
            str_arr.push(lzw_compress(v))

        }

        str = str_arr.join("__________")
        // console.log("___压缩后编码："+str.length)
    }

    // console.log(str)
    return str;
};



let canDecode = function (str) {
return str.indexOf("%")===0;
}

let isOld = function (content){
    if(!content){
        return false;
    }
    let size  = content.length;
    let count = 0;
    for(let i of content){
        if(i.charCodeAt(0).toString(16).length===4){
            count++;
        }
    }
    //如果超过一半是乱码，就是老的
    return  count*2>size;

}

let jsJson = function (str){
    try {
        if(str.indexOf("{")===0){
            return true;
        }
        JSON.parse(str)
        return  true;
    }catch (e) {
        return false;
    }
}

window.unzip = function (b64Data){

    let strData     = atob(b64Data);
    let charData    = strData.split('').map(function(x){return x.charCodeAt(0);});
    let binData     = new Uint8Array(charData);
    let res     = pako.inflate(binData,{to: 'string'});
    // console.log("处理结果:",res.indexOf("%"),res)
    //现在的办法是简单处理，要严格验证出是可decode的
    if(canDecode(res)){
        res =  decodeURIComponent(res);
    }else {
        //无encodeURIComponent  不处理
    }
    return res;
}

window.zip = function (str){
    // let unit8 = pako.gzip(str);
    // let gzi = Buffer.from(unit8, 'hex');
    // let input = gzi.toString('base64');
    // let a = Compress(str);
    // console.log("老的压缩2",input.length,input)
    let binaryString = pako.gzip(str, { to: 'string' });
    let d = btoa(binaryString)
    // console.log(d)
    return d;
}
window.unzip2 = function (str) {
    //兼容解压，如果是之前的压缩，用之前的解压。如果是现在

   let old =  isOld(str)
    if(old){
        return Decompress(str);
    }else {
        if(canDecode(str)){
            return decodeURIComponent(str)
        }else {
            return window.unzip(str)
        }

    }
}
window.objectToStr = function (obj,tag){

    if(typeof obj  === "string"){
        return  window.zip(obj);
    }else{
        return  window.zip(JSON.stringify(obj));
    }

}
window.strToObject = function (text,tag){

    tag = window.getUID()+ "___"+ tag;
    let oldText = text;
    try {
        // console.log(tag+"___开始转换：",oldText)
        let str;
        if(!jsJson(text)){

            if(canDecode(text)){
                str =  decodeURIComponent(text);
                // console.log(tag+"___解析后：",str)
            }else {
                //无encodeURIComponent  不处理
                str = window.unzip(text);
                // console.log(tag+"___解压后：",str)
            }
            //如果不是JSON。直接解压

        }else {
            str  = text;
            // console.log(tag+"___无需解析：",str)
        }
        return JSON.parse(str);
    }catch (e){
        console.error(tag+"___字符转json出错：",oldText,text,e)
        try {
            return JSON.parse(oldText)
        }catch (e) {
            console.error(tag+"___字符转json修复出错：",oldText,e)
            return null;
        }

    }
}