import React, {Component} from "react";
const ConfigData = require('./main/leftMenu/PermissionConfig');
const KeyArray = ConfigData.keyArray;
import {message} from "antd";

window.___ = {

    navigationData: [],

    ChildPage: (Page) => {
        class ChildPage extends Component {
            render() {
                return <Page {...this.props} />;
            }
        };
        return ChildPage;
    },

    toTreeData: (data, attributes, objectStyleCall) => {
        if (!data) {
            return [];
        }
        window.___.navigationData = _.merge([], data);
        //创建虚拟根结构
        let tree = [{
            [attributes.parentId]: attributes.rootIdValue,
            [attributes.children]: []
        }];

        let run = (chiArr) => {
            if (data.length !== 0) {
                for (let i = 0; i < chiArr.length; i++) {
                    for (let j = 0; j < data.length; j++) {
                        if (chiArr[i][attributes.parentId] == data[j][attributes.parentId]) {
                            let call = objectStyleCall(data[j]);
                            chiArr[i][attributes.children].push(
                                Object.assign(
                                    call,
                                    {
                                        [attributes.parentId]: data[j][attributes.curId],
                                        [attributes.children]: [],
                                    }));
                            data.splice(j, 1);
                            j--;
                        }
                    }
                    //  log(chiArr[i][attributes.children])
                    run(chiArr[i][attributes.children]);
                }
            }
        };
        run(tree);
        return tree[0][attributes.children];
    },

    //将导航与权限匹配
    checkPower(NavList,powerTree){
        if(!powerTree){
            return [];
        }
        let resultNav = [];
        for(let obj of powerTree){
            for(let nav of NavList){
                if(nav.id == obj.key || nav.parentId == obj.key){
                    nav.functions = obj.children;
                    for(let power of nav.functions){
                        if(power.name == 'Query' && !!power.permit){
                            resultNav.push(nav);
                            break;
                        }
                    }
                }
            }
        }

        return resultNav;
    },


    getPower: (path) => {
        if (!path) {
            return {};
        }
        let nav = window.___.navigationData;
        let functions = [];
        for (let obj of nav) {
            if (obj.id === path) {
                functions = obj.functions;
            }
        }

        return window.___.initPower(functions);
    },

    initPower(functions) {
        if (!functions) {
            return {};
        }
        log(functions);
        let find = (type) => {
            let index = _.findIndex(functions, (obj) => {
                return obj.permit && obj.name == type;
            });
            if (index >= 0) {
                return functions[index];
            } else {
                return null;
            }
        };

        let result = {};
        KeyArray.forEach((item)=>{
            result[item.key] = find(item.key)
        });

        return result;
    }
};

//格式化价格数字，每三位插入逗号
window.formatPrice = function(number){
    if (number === undefined || number === null) {
        return '';
    }

    return parseFloat(number).toLocaleString('en-US');
}

window.getUID = function () { // 获取唯一值
    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        let r = Math.random() * 16 | 0;
        let v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

window.copyText = function (str) {
    let oInput = document.createElement('textarea')
    oInput.value = str
    document.body.appendChild(oInput)
    oInput.select()
    document.execCommand("Copy")
    oInput.style.display = 'none'
    document.body.removeChild(oInput)

    if (str && str.length > 100) {
        str = str.substring(0, 100);
    }
    message.success(<div style={{whiteSpace: "pre-wrap", textAlign: "left"}}>
        <div>{"复制成功:"}</div>
        <div>{str}</div>
    </div>)
}

//前端下载文件
window.downFileByUrl = function(url, fileName) {
    if(!url){
        message.error('无效的资源路径');
        return;
    }

    //不使用form方式下载，地址错误的时候会改变当前页面
    // let form = document.createElement("form");
    // document.body.appendChild(form);
    // form.action = url;
    // form.submit();

    let downLink = document.createElement('a')
    downLink.download = fileName || "下载内容";
    downLink.href = url;
    downLink.target= "_black";
    // 链接插入到页面
    document.body.appendChild(downLink)
    downLink.click()
    // 移除下载链接
    document.body.removeChild(downLink)
}
