export default {
    publicKey:
      "-----BEGIN PUBLIC KEY-----\n" +
      "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC/4ulVTnbc7RAr1xKS0B5Qrca6\n" +
      "6I/PFqqy5VvhiYgWQibAcVGiArqHo+Xv+vhyMkblIrUx6Rt/S4wysgf/zotzaimB\n" +
      "YTmoxiLHcCWeMgEPNgAwMs+w67uYVp0lMBmW7qY5/B9DnJ3i0uhnvDTojnzDpXPn\n" +
      "KEvWC/N9+irUX/DJPQIDAQAB\n" +
      "-----END PUBLIC KEY-----", //HttpTools 加密公钥；
    projectUniqueName: "SYBER_ADMIN",//项目cookies存储用
    requestTimeout: 60000,  //接口请求超时(ms)
};
