import React, {Component} from 'react';
import AreaSelect from "./AreaSelect";

/**
 * 输入框模板
 */
class LayoutAreaSelect extends Component {
    constructor(props) {
        super(props);
    }
    // _mergeParameter(parameter,resultValue){
    //     _.merge(parameter,resultValue);
    // }
    //
    // _verParameter(data){
    //     return  this.rightObject.verValue(data);
    // }

    _initDefaultValue(data){
        return data.option.defaultValue||undefined;
    }

    render(){
        let {option,data,verification} = this.props;
        return (
            <div>
                <AreaSelect
                    ref={(ref)=>{
                        this.rightObject = ref;
                    }}
                    {...option}
                    onChange={(e) => {
                        verification(data,e);
                    }}
                />
            </div>
        );
    }
}


export default LayoutAreaSelect;