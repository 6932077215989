import React, {Component} from 'react';
import CountrySelect from  "./CountrySelect";

/**
 * 输入框模板
 */
class LayoutCountrySelect extends Component {
    constructor(props) {
        super(props);
    }

    _initDefaultValue(data){
        return data.option.defaultValue||undefined;
    }

    render(){
        let {option,data,verification} = this.props;
        return (
            <div>
                <CountrySelect
                    ref={(ref)=>{
                        this.rightObject = ref;
                    }}
                    {...option}
                    onChange={(e) => {
                        verification(data,e);
                    }}
                />
            </div>
        );
    }
}


export default LayoutCountrySelect;