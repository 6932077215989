import React, {Component} from 'react';
import {Input,message} from "antd";
import jsQR from "jsqr";

/**
 * 输入框模板
 */
class LayoutCountrySelect extends Component {
  constructor(props) {
    super(props);

    this.state={
      value: null
    }
  }

  _initDefaultValue(data){
    return data.option.defaultValue||undefined;
  }

  dataChange(){
    let {option,data,verification} = this.props;

    verification(data,this.state.value);
  }

  render(){
    let {option,data,verification} = this.props;
    return (
      <div>
        <Input
          value={this.state.value}
          {...option}
          onChange={(e) => {
            let v = e.target.value

            this.setState({
              value: v
            },()=>{
              this.dataChange();
            })
          }}
        />
        <input type="file" accept='image/*' onChange={(e)=>{
          let targetFile = e.target.files[0];

          this.dealFile(targetFile)
        }}/>
      </div>
    );
  }

  dealFile(file){
    console.log(file)
    const reader = new FileReader();
    reader.onload = (e)=> {
      const img = new Image();
      img.onload = ()=> {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        context.drawImage(img, 0, 0, img.width, img.height);
        const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
        const code = jsQR(imageData.data, imageData.width, imageData.height);

        if (code) {
          this.setState({
            value: code.data
          },()=>{
            this.dataChange()
          })
        } else {
          message.warning("未从图片中识别出有效信息");
        }
      };
      img.src = e.target.result;
    };

    reader.readAsDataURL(file);
  }
}


export default LayoutCountrySelect;