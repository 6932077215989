import React from 'react';
import ListPage from '../../base/ListPage.js';
import APILXD from '../../../http/APILXD.js';
import {Modal, message} from 'antd';
import TimeHelp from "../../../tool/TimeHelp";
import ModalBase from "../../../components/modalBase/index";
import HttpTool from "../../../tool/HttpTool";
import styles from "./Company.less";

const MB = new ModalBase();

class Company extends ListPage {
    constructor(props) {
        super(props);
    }

    base_getListConfig() {
        return {
            table: {
                columns: this.getTableColumns(),
                url: APILXD.companyIndex,
                otherParam: null,
            },
            new: {
                defaultValue: '添加公司',
                action: () => {
                    this.add();
                }
            },
            search: {
                seniorSearch: this.getSeniorSearch(),
                option: {
                    openSenior: true,
                    placeholder: '请输入公司名称搜索',
                    reg: /^[\s\S]*$/,
                    beforeSubmit: (value) => {
                        if(value.keyword){
                            value.name = value.keyword
                        }
                        delete value.keyword

                        return true;
                    },
                }
            }
        }
    }

    getAddData(data) {
        let defaultOption = {
            type: "input",
            ver: true,
            reg: /^[\s\S]{1,50}$/,
            verMessage: "请输入",
            required: true,
        };
        let props = {
            colCount: 1,
            formItemLayout: {
                labelCol: {span: 6},
                wrapperCol: {span: 12, offset: 0},
            },
            parameterArr: [
                {
                    ...defaultOption,
                    field: "_id",
                    name: '公司编码',
                    required: "true",
                    option: {
                        placeholder: '请输入公司编码',
                    }
                },
                {
                    ...defaultOption,
                    field: "name",
                    name: '公司名',
                    required: "true",
                    option: {
                        placeholder: '请输入公司名',
                    }
                },
                {
                    ...defaultOption,
                    field: "status",
                    name: "是否启用",
                    reg: (v) => {
                        return !!v;
                    },
                    verMessage: "请选择是否启用",
                    type: 'Select',
                    selectType: 'value',
                    required: "true",
                    ver: true,
                    data: [
                        {
                            title: '启用',
                            value: 1,
                        },
                        {
                            title: '禁用',
                            value: 2,
                        },
                    ],
                },
            ],
        };
        return {
            props,
            otherParam: null
        }
    }

    add() {
        let addData = this.getAddData({});
        MB.show(
            {
                title: "添加公司",
                okTitle: "新增",
                closeTitle: "取消",
            },
            addData.props
            ,
            {
                url: APILXD.companyCreate,
                beforeSubmit: (param) => {
                    param.name = _.trim(param.name);
                    param._id = _.trim(param._id);

                    return true;
                },
                callBack: (state, msg) => {
                    //添加成功回调
                    state == 'success' && this._loadDataForNet();
                }
            });
    }

    getSeniorSearch() {
        return {
            colCount: 3,
            formItemLayout: {
                labelCol: {span: 6},
                wrapperCol: {span: 18, offset: 0},
            },
            parameterArr: [
                {
                    field: "status",
                    name: "状态",
                    type: 'select',
                    selectType: 'value',
                    reg: (v) => {
                        return true;
                    },
                    option: {
                        defaultValue: {
                            key: '全部状态',
                            value: '',
                        },
                    },
                    data: [
                        {
                            title: '全部状态',
                            value: '',
                        },
                        {
                            title: '正常',
                            value: 1,
                        },
                        {
                            title: '禁用',
                            value: 2,
                        },
                    ]
                },
            ],
        }
    }

    changeAccountStatus(id, status) {
        let param = {
            id,
            status,
        };
        let successCB = (code, msg, json, option) => {
            this.setState({loading: false}, () => {
                message.success('操作成功');
                this._loadDataForNet();
            });
        };
        let failureCB = (code, msg) => {
            this.setState({loading: false}, () => {
                message.error(msg);
            });
        };

        this.setState({loading: true});

        HttpTool.post(APILXD.enableAdmin, successCB, failureCB, param);
    }


    /**
     *  列表数据配置
     */
    getTableColumns() {
        return [
            {
                title: '公司编码',
                dataIndex: '_id',
                key: '_id',
            },
            {
                title: '公司名',
                dataIndex: 'name',
                key: 'name',
            },

            {
                title: '添加时间',
                dataIndex: 'addTime',
                key: 'addTime',
                render: (text) => {
                    let time = TimeHelp.getYMDHM(text);
                    return <div>{time}</div>
                }
            },

            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render: (text) => {
                    return text == 1 ? <span style={{color: 'green'}}>启用</span> : <span style={{color: 'red'}}>禁用</span>
                }
            },
            {
                title: '操作',
                width: 200,
                render: (text, record, index) => {
                    return (
                        <div>
                            {
                                this.powerConfig.Edit
                                    ?(record.status === 1 ? <span onClick={() => {
                                      this.changeState(record._id, 2)
                                  }} style={{color: "red"}} className={styles.clickable}>{"禁用"}</span> :
                                  <span onClick={() => {
                                      this.changeState(record._id, 1)
                                  }} style={{color: "green"}} className={styles.clickable}>{"启用"}</span>)
                                    :null
                            }
                            {
                                this.powerConfig.Edit
                                  ?<span onClick={() => {
                                      this.modify(record)
                                  }} className={styles.clickable} style={{marginLeft: 10}}>编辑</span>
                                  :null
                            }
                            {
                                this.powerConfig.Delete
                                  ?<span onClick={() => {
                                      this.deleteOne(record)
                                  }} className={styles.clickable} style={{marginLeft: 10, color: "red"}}>删除</span>
                                  :null
                            }
                        </div>
                        )
                }
            },
        ]
    }

    deleteOne(record){
        Modal.confirm({
            title: '提示',
            content: "确定删除该公司吗",
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
                let param = {
                    _id: record._id,
                };
                let successCB = (code, msg, json, option) => {
                    this.setState({loading: false}, () => {
                        message.success('操作成功');
                        this._loadDataForNet();
                    });
                };
                let failureCB = (code, msg) => {
                    this.setState({loading: false}, () => {
                        message.error(msg);
                    });
                };

                this.setState({loading: true});

                HttpTool.post(APILXD.companyDelete, successCB, failureCB, param);
            }
        })
    }

    changeState(id, status) {
        Modal.confirm({
            title: '提示',
            content: ("确定" + status === 1 ? "启用" : "禁用") + "该公司吗",
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
                let param = {
                    _id: id,
                    status,
                };
                let successCB = (code, msg, json, option) => {
                    this.setState({loading: false}, () => {
                        message.success('操作成功');
                        this._loadDataForNet();
                    });
                };
                let failureCB = (code, msg) => {
                    this.setState({loading: false}, () => {
                        message.error(msg);
                    });
                };

                this.setState({loading: true});

                HttpTool.post(APILXD.companyUpdate, successCB, failureCB, param);
            }
        })
    }

    modify(data) {
        let defaultOption = {
            type: "input",
            ver: true,
            reg: /^[\s\S]{1,50}$/,
            verMessage: "请输入",
            required: true,
        };
        let addData = {
            props: {
                colCount: 1,
                formItemLayout: {
                    labelCol: {span: 6},
                    wrapperCol: {span: 12, offset: 0},
                },
                parameterArr: [
                    {
                        ...defaultOption,
                        field: "name",
                        name: '公司名',
                        required: "true",
                        option: {
                            placeholder: '请输入公司名',
                            defaultValue: data.name
                        }
                    },
                ],
            },
            otherParam: null
        };


        MB.show(
            {
                title: "修改公司",
                okTitle: "修改",
                closeTitle: "取消",
            },
            addData.props
            ,
            {
                url: APILXD.companyUpdate,
                beforeSubmit: (param) => {
                    param.name = _.trim(param.name);
                    param._id = _.trim(data._id);
                    return true;
                },
                callBack: (state, msg) => {
                    //添加成功回调
                    state == 'success' && this._loadDataForNet();
                }
            });
    }
}

module.exports = Company;