import React, {Component} from 'react';
import {Switch} from 'antd';

/**
 * 单选模板
 */
class Index extends Component {
    constructor(props) {
        super(props);
    }

    _initDefaultValue(data) {
        return data.option.defaultValue;
    }

    date = (checkedValues) => {
        this.props.verification(this.props.data, checkedValues)
    }

    render() {
        let {data} = this.props;
        return (
            <div>
                {(data.purpose == "edit" || !data.purpose) ?
                    <Switch
                        {...this.props.option}
                        defaultChecked={this.props.option.defaultValue}
                        onChange={this.date}
                    /> : <div>{data.option && data.option.defaultValue.join(" ")}</div>
                }
            </div>
        )
    }
}

export default  Index;