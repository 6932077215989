import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Select, Spin } from 'antd';
import HttpTool from "../../../tool/HttpTool";

const Option = Select.Option;

/**
 * 输入框模板
 *
 * 默认值写法
 * 当用于selectTyle：value时
 otpion.defaultValue:{
                            key:"这是data【0】.value值",
                             value:"2222"

                        }
 * 当用于selectTyle：title时
 otpion.defaultValue:{
                            key:这是data【0】.value值",
                             title:"这是显示值"

                        }
 */
class LayoutSelect extends Component {
    constructor(props) {
        super(props);

        //发送都请求次数
        this.apiAction = 0;

        this.state = {
            dataList: this.props.data.data ? this.props.data.data : [],
            fetching: false,
            forceUpdate: 0,
        };

        //发送都请求次数
        this.apiAction = 0;
    }

    componentDidMount() {
        // let {data} = this.props;
        // if (data.url) {
        //     HttpTool.post(data.url,
        //         (code, msg, json, option) => {
        //             data.data = json
        //         }, (code, msg, option) => {
        //
        //         }, {});
        // }
    }

    _initDefaultValue(data) {
        return data.option.defaultValue &&
        data.option.defaultValue[data.selectType] != undefined
          ? data.option.defaultValue[data.selectType]
          : data.option.defaultValue;
    }

    fetchUser = (value) => {
        let { data } = this.props;

        let param = (value && value.replace(/(^\s*)|(\s*$)/g, '')) || null;
        if (!param) {
            return;
        }

        let obj = {};
        this.setState({ data: [], fetching: true });

        //排序+1，记录本次请求排序
        let currAction = ++this.apiAction;
        if (data.realtime && data.realtime.url && data.realtime.name) {
            if (!data.realtime.obj) {
                obj[data.realtime.name] = param;
            } else {
                obj = data.realtime.obj;
                obj[data.realtime.name] = param;
            }
            HttpTool.post(
              data.realtime.url,
              (code, msg, json, option) => {
                  if (currAction < this.apiAction) {
                      return;
                  }
                  setTimeout(() => {
                      data.data = data.realtime.fillObject
                        ? data.realtime.fillObject(json)
                        : json;
                      this.setState(
                        {
                            fetching: false,
                            dataList: data.realtime.fillObject
                              ? data.realtime.fillObject(json)
                              : json,
                        },
                        () => {
                            console.log(this.state.data);
                        },
                      );
                  }, 100);
              },
              (code, msg, option) => {},obj
            );
        }
    };

    render() {
        let { data } = this.props;
        let { fetching, dataList, forceUpdate } = this.state;

        if (this.props.option.defaultValue) {
            this.props.option.defaultValue.value = String(
              this.props.option.defaultValue.value,
            );
        }
        return (
          <div
            ref={(ref) => {
                this.selectBox = ref;
            }}
          >
              {data.purpose == 'edit' || !data.purpose ? (
                <Select
                  key={'forceUpdate_' + forceUpdate}
                  getPopupContainer={() => {
                      return ReactDOM.findDOMNode(this.selectBox);
                  }}
                  labelInValue={true}
                  notFoundContent={fetching ? <Spin size="small" /> : null}
                  onSearch={this.fetchUser}
                  {...this.props.option}
                  onChange={(e) => {
                      if (!e) {
                          data.resultValue = null;
                      } else {
                          if (data.selectType === 'value') {
                              //value是唯一值，拿到value对应的类型，进行反转
                              for (let { temp } of dataList) {
                                  //antd v4.22.0 e.key 返回的是 下标，改成 e.value
                                  if (temp.value === e.value) {
                                      data.resultValue = this.formatValueType(
                                        temp.type,
                                        temp.value,
                                      );
                                      break;
                                  }
                              }
                          } else {
                              data.resultValue = e.label;
                          }
                      }
                      this.props.verification(data, data.resultValue);
                  }}
                >
                    {dataList
                      ? dataList.map((obj, key) => {
                          obj.temp = this.getValueTypeObject(obj.value);
                          return (
                            <Option value={obj.temp.value} key={key}>
                                {obj.title}
                            </Option>
                          );
                      })
                      : null}
                </Select>
              ) : (
                <div>{this.sortOut()}</div>
              )}
          </div>
        );
    }

    sortOut = () => {
        let { data } = this.props;
        let str = '';
        if (data.option && data.option.defaultValue) {
            //此处，在展示详情的时候因该直接显示title的值即可，不做判断
            if (data.option.defaultValue.title) {
                str = data.option.defaultValue.title;
            } else if (data.data) {
                let matchKey =
                  data.option.defaultValue.key || data.option.defaultValue.value;
                for (let i = 0; i < data.data.length; i++) {
                    if (String(data.data[i].value) === String(matchKey)) {
                        str = data.data[i].title;
                    }
                }
            }
        }
        return str;
    };

    getValueTypeObject(value) {
        if (value === null) {
            return {
                type: 'null',
                value: 'null',
            };
        }
        if (value === undefined) {
            return {
                type: 'undefined',
                value: 'undefined',
            };
        }

        let type = typeof value;

        return {
            type: type,
            value: type === 'object' ? JSON.stringify(value) : value.toString(),
        };
    }

    formatValueType(type, value) {
        // console.log("看看返回的是个啥")
        // console.log(type)
        // console.log(value)
        switch (type) {
            case 'boolean':
                return value === 'true';
            case 'number':
                return new Number(value).valueOf();
            case 'string':
                return new String(value).valueOf();
            case 'object':
                return JSON.parse(value);
            case 'undefined':
                return undefined;
            case 'null':
                return null;
            default:
                return value;
        }
    }

    test(t) {
        let obj = this.getValueTypeObject(t);
        let c = this.formatValueType(obj.type, obj.value);
        return c;
    }
}

export default LayoutSelect;