import React from 'react';
import ListPage from '../../base/ListPage.js';
import APILXD from '../../../http/APILXD.js';
import {Modal, message} from 'antd';
import TimeHelp from "../../../tool/TimeHelp";
import md5 from "md5";
import styles from "./Company.less";
import moment from "moment";
import ModalBase from "../../../components/modalBase/index";
import HttpTool from "../../../tool/HttpTool";

const MB = new ModalBase();

class AccountPersonal extends ListPage {
    constructor(props) {
        super(props);
    }

    base_getListConfig() {
        let obj = {
            new: {
                defaultValue: '添加用户账号',
                action: () => {
                    this.add();
                }
            },
            search: {
                seniorSearch: this.getSeniorSearch(),
                option: {
                    openSenior: true,
                    placeholder: '请输入关键字搜索',
                    reg: /^[\s\S]*$/,
                    beforeSubmit: (value) => {
                        if (value.companyId && typeof value.companyId !== "string") {
                            value.companyId = value.companyId.key
                        }

                        return true;
                    }
                }
            }
        };


        obj["table"] = {
            columns: this.getTableColumns(),
            url: APILXD.userIndex,
            otherParam: this.props.post.companyId?{companyId: this.props.post.companyId}:{},
        };

        return obj;
    }


    getAddData(data) {
        let defaultOption = {
            type: "input",
            ver: true,
            reg: /^[\s\S]{1,50}$/,
            verMessage: "请输入",
            required: true,
        };

        let option = {}
        if (data && data.companyId) {
            option = {
                defaultValue: {
                    key: data.companyId,
                    value: data.companyId,
                }
            }
        }

        let optionStatus = {}
        if (data && data.status) {
            optionStatus = {
                defaultValue: {
                    key: data.status,
                    value: data.status,
                }
            }
        }

        let props = {
            colCount: 1,
            formItemLayout: {
                labelCol: {span: 6},
                wrapperCol: {span: 12, offset: 0},
            },
            parameterArr: [
                {
                    field: "companyId",
                    name: "公司名称",
                    type: 'select',
                    selectType: 'value',
                    required: true,
                    ver: true,
                    verMessage: "请选择公司",
                    reg: (v) => {
                        return !!v;
                    },
                    apiConfig: {
                        url: APILXD.companySelect,
                        fillObject: (json) => {
                            let data = [];
                            for (let obj of json) {
                                data.push({
                                    title: obj.name,
                                    value: obj._id,
                                });
                            }
                            return data;
                        }
                    },
                    data: [],
                    option: Object.assign({
                        disabled: !!data,
                        dropdownMatchSelectWidth: false,
                        showSearch: true,
                        onSearch: () => {},
                        filterOption: (input, option) => {
                            return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        },
                        optionFilterProp: "children",
                    },option)
                },
                {
                    ...defaultOption,
                    field: "account",
                    name: '账号',
                    required: "true",
                    reg: /^[0-9A-Za-z_@]{6,30}$/,
                    verMessage: "请输入6-30位账号，仅支持英文、数字、下划线和@",
                    option: {
                        disabled: !!data,
                        placeholder: '请输入6-30位账号',
                        maxLength: 30,
                        defaultValue: data && data.account
                    }
                },
                {
                    ...defaultOption,
                    field: "nickname",
                    name: '昵称',
                    required: "true",
                    reg: /^[\s\S]{1,10}$/,
                    verMessage: "请输入昵称",
                    option: {
                        placeholder: '请输入昵称，1-10个字符',
                        maxLength: 10,
                        defaultValue: data && data.nickname
                    }
                },


            ],
        };
        if (!data) {
            props.parameterArr.push({
                    ...defaultOption,
                    field: "passwd",
                    name: '密码',
                    required: "true",
                    verMessage: '请输入密码,最多32位',
                    option: {
                        placeholder: '请输入密码,最多32位',
                        type: 'password',
                        maxLength: 32
                    }
                },
                {
                    ...defaultOption,
                    field: "passwd2",
                    name: '确认密码',
                    required: "true",
                    verMessage: '请确认密码,最多32位',
                    option: {
                        placeholder: '请确认密码,最多32位',
                        type: 'password',
                        maxLength: 32
                    }
                },)
        }

        props.parameterArr.push({
            field: "status",
            name: "是否启用账号",
            reg: (v) => {
                return !!v;
            },
            verMessage: "请选择是否启用",
            type: 'Select',
            selectType: 'value',
            required: "true",
            ver: true,
            data: [
                {
                    title: '启用',
                    value: 1,
                },
                {
                    title: '禁用',
                    value: 2,
                },
            ],
            option: optionStatus
        });
        props.parameterArr.push({
            field: "expireTimeSet",
            name: "过期时间",
            reg: (v) => {
                return true;
            },
            tips: "请选择",
            type: 'DatePicker',
            required: false,
            ver: true,
            option: {
                style: {width: "100%"},
                showTime: true,
                placeholder: "不选择过期时间则代表永久有效",
                format: "YYYY-MM-DD HH:mm:ss",
                defaultValue:
                  (data && data.expireTime && moment(data.expireTime)) ||
                  undefined,
                disabledDate: (value) => {
                    if (!value) {
                        return false;
                    }
                    let nowDate = new Date();
                    return value.valueOf() < nowDate.valueOf() - 86400000;
                },
            }
        });
        return {
            props,
            otherParam: null
        }
    }

    add() {
        let addData = this.getAddData();
        MB.show(
            {
                title: "添加账号",
                okTitle: "添加",
                closeTitle: "取消",
            },
            addData.props
            ,
            {
                url: APILXD.userCreate,
                beforeSubmit: (param) => {
                    if (param.passwd !== param.passwd2) {
                        message.error("两次密码输入不一致");
                        return false;
                    }
                    param.account = _.trim(param.account);
                    param.nickname = _.trim(param.nickname);
                    param.passwd = md5(_.trim(param.passwd));
                    delete param.passwd2;

                    if(param.expireTimeSet){
                        console.log(param.expireTimeSet)
                        param.expireTime = param.expireTimeSet.valueOf()
                    }
                    delete param.expireTimeSet;

                    return true;
                },
                callBack: (state, msg) => {
                    //添加成功回调
                    state == 'success' && this._loadDataForNet();
                }
            });
    }

    getSeniorSearch() {
        return {
            colCount: 3,
            formItemLayout: {
                labelCol: {span: 6},
                wrapperCol: {span: 18, offset: 0},
            },
            parameterArr: [
                {
                    field: "companyId",
                    name: "公司名称",
                    type: 'select',
                    selectType: 'value',
                    reg: (v) => {
                        return !!v;
                    },
                    apiConfig: {
                        url: APILXD.companySelect,
                        fillObject: (json) => {
                            let data = [];
                            for (let obj of json) {
                                data.push({
                                    title: obj.name,
                                    value: obj._id,
                                });
                            }
                            data.unshift({
                                title: '全部公司',
                                value: '',
                            });

                            return data;
                        }
                    },
                    option: {
                        dropdownMatchSelectWidth: false,
                        showSearch: true,
                        onSearch: () => {},
                        placeholder: "输入名称可以精确匹配",
                        filterOption: (input, option) => {
                            return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        },
                        optionFilterProp: "children",
                        defaultValue: this.props.post.companyId ? {
                            key: this.props.post.companyId + ""
                        } : {
                            key: '',
                            value: '',
                            title: '全部公司'
                        },
                    },
                    data: []
                },
                {
                    field: "status",
                    name: "账号状态",
                    type: 'select',
                    selectType: 'value',
                    reg: (v) => {
                        return true;
                    },
                    option: {
                        defaultValue: {
                            key: '全部状态',
                            value: '',
                        },
                    },
                    data: [
                        {
                            title: '全部状态',
                            value: '',
                        },
                        {
                            title: '启用',
                            value: 1,
                        },
                        {
                            title: '禁用',
                            value: 2,
                        },
                    ]
                },
            ],
        }
    }

    /**
     *  列表数据配置
     */
    getTableColumns() {
        return [
            {
                title: '用户账号',
                dataIndex: 'account',
                key: 'account',
            },
            {
                title: '用户昵称',
                dataIndex: 'nickname',
                key: 'nickname',
            },
            {
                title: '公司编码',
                dataIndex: 'companyId',
                key: 'companyId',
            },
            {
                title: '公司名',
                dataIndex: 'companyName',
                key: 'companyName',
            },
            {
                title: '添加时间',
                dataIndex: 'addTime',
                key: 'addTime',
                render: (text) => {
                    let time = TimeHelp.getYMDHM(text);
                    return <div>{time}</div>
                }
            },
            {
                title: '过期时间',
                dataIndex: 'expireTime',
                key: 'expireTime',
                render: (text) => {
                    let time = text?TimeHelp.getYMDHM(text):"长期有效";
                    return <div>
                        {time}
                        {
                            text && text < Date.now()
                                ?<span style={{color: "red"}}>（已过期）</span>
                                :null
                        }
                    </div>
                }
            },
            {
                title: '账号状态',
                dataIndex: 'status',
                key: 'status',
                render: (text) => {
                    return text == 1 ? <span style={{color: 'green'}}>启用</span> : <span style={{color: 'red'}}>禁用</span>
                }
            },
            {
                title: '操作',
                width: 250,
                render: (text, record, index) => {
                    return (
                      <div>
                          {
                              this.powerConfig.Edit
                                ?(record.status === 1 ? <span onClick={() => {
                                    this.changeState(record._id, 2)
                                }} style={{color: "red",}} className={styles.clickable}>{"禁用"}</span> :
                                <span onClick={() => {
                                    this.changeState(record._id, 1)
                                }} style={{color: "green"}} className={styles.clickable}>{"启用"}</span>)
                                :null
                          }
                          {
                              this.powerConfig.Edit
                                ?<span onClick={() => {
                                    this.edit(record)
                                }} style={{color: "blue", marginLeft: 10}} className={styles.clickable}>编辑</span>
                                :null
                          }
                          {
                              this.powerConfig.Edit
                                ?<span onClick={() => {
                                    this.resetPassword(record)
                                }} className={styles.clickable}
                                       style={{marginLeft: 10}}>重置密码</span>
                                :null
                          }
                          {
                              this.powerConfig.Delete
                                ?<span onClick={() => {
                                    this.deleteOne(record)
                                }} className={styles.clickable} style={{marginLeft: 10, color: "red"}}>删除</span>
                                :null
                          }
                      </div>
                    )
                }
            },
        ]
    }

    changeState(id, status) {
        Modal.confirm({
            title: '提示',
            content: ("确定" + status === 1 ? "启用" : "禁用") + "该用户吗",
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
                let param = {
                    _id: id,
                    status,
                };
                let successCB = (code, msg, json, option) => {
                    this.setState({loading: false}, () => {
                        message.success('操作成功');
                        this._loadDataForNet();
                    });
                };
                let failureCB = (code, msg) => {
                    this.setState({loading: false}, () => {
                        message.error(msg);
                    });
                };

                this.setState({loading: true});

                HttpTool.post(APILXD.userUpdate, successCB, failureCB, param);
            }
        })
    }

    resetPassword(data) {
        let defaultOption = {
            type: "input",
            ver: true,
            reg: /^[\s\S]{1,50}$/,
            verMessage: "请输入",
            required: true,
        };
        let addData = {
            props: {
                colCount: 1,
                formItemLayout: {
                    labelCol: {span: 6},
                    wrapperCol: {span: 12, offset: 0},
                },
                parameterArr: [{
                    ...defaultOption,
                    field: "passwd",
                    name: '密码',
                    required: "true",
                    verMessage: '请输入密码,最多32位',
                    option: {
                        placeholder: '请输入密码,最多32位',
                        type: 'password',
                        maxLength: 32
                    }
                },
                    {
                        ...defaultOption,
                        field: "passwd2",
                        name: '确认密码',
                        required: "true",
                        verMessage: '请确认密码,最多32位',
                        option: {
                            placeholder: '请确认密码,最多32位',
                            type: 'password',
                            maxLength: 32
                        }
                    }],
            },
            otherParam: null
        }

        MB.show(
            {
                title: "重置密码",
                okTitle: "重置",
                closeTitle: "取消",
            },
            addData.props
            ,
            {
                url: APILXD.userUpdate,
                beforeSubmit: (param) => {
                    if (param.passwd !== param.passwd2) {
                        message.error("两次密码输入不一致");
                        return false;
                    }
                    param.passwd = md5(_.trim(param.passwd));
                    param._id = data._id;
                    delete param.passwd2;
                    return true;
                },
                callBack: (state, msg) => {
                    //添加成功回调
                    state == 'success' && this._loadDataForNet();
                }
            });

    }

    edit(data) {
        let addData = this.getAddData(data);
        MB.show(
            {
                title: " 修改账号",
                okTitle: "修改",
                closeTitle: "取消",
            },
            addData.props
            ,
            {
                url: APILXD.userUpdate,
                beforeSubmit: (param) => {
                    param.account = _.trim(param.account);
                    param.nickname = _.trim(param.nickname);
                    if (typeof param.status !== "number") {
                        if (param.status && param.status.key) {
                            param.status = Number(param.status.key)
                        } else {
                            Number(param.status)
                        }
                    }
                    if (typeof param.companyId !== "string") {
                        if (param.companyId && param.companyId.key) {
                            param.companyId = param.companyId.key
                        }
                    }

                    if(param.expireTimeSet){
                        console.log(param.expireTimeSet)
                        param.expireTime = param.expireTimeSet.valueOf()
                    }

                    delete param.expireTimeSet;
                    param._id = data._id;
                    return true;
                },
                callBack: (state, msg) => {
                    //添加成功回调
                    state == 'success' && this._loadDataForNet();
                }
            });
    }

    deleteOne(record){
        Modal.confirm({
            title: '提示',
            content: "确定删除该账号吗",
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
                let param = {
                    _id: record._id,
                };
                let successCB = (code, msg, json, option) => {
                    this.setState({loading: false}, () => {
                        message.success('操作成功');
                        this._loadDataForNet();
                    });
                };
                let failureCB = (code, msg) => {
                    this.setState({loading: false}, () => {
                        message.error(msg);
                    });
                };

                this.setState({loading: true});

                HttpTool.post(APILXD.userDelete, successCB, failureCB, param);
            }
        })
    }
}

module.exports = AccountPersonal;