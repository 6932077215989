import React from 'react';
import ListPage from '../../../base/ListPage.js';
import less from '../ApplicationManage.less';
import APILXD from '../../../../http/APILXD.js';
import TimeHelp from '../../../../tool/TimeHelp';
import {Popover, message, Modal} from 'antd';
import ModalDel from '../../../../components/modalDelete/ModalDelete.js';
import ModalBase from '../../../../components/modalBase/index.js';
import HttpTool from "../../../../tool/HttpTool";

const MB = new ModalBase();
const ModalDelete = new ModalDel();

class VersionList extends ListPage {
    constructor(props) {
        super(props);
    }

    base_getListConfig() {
        return {
            table: {
                title: "应用-版本列表",
                columns: this.getTableColumns(),
                url: APILXD.getAppVersionList,
                otherParam: {
                    appid: this.props.post.appid
                },
                option: {
                    expandedRowRender: (record) => {
                        return (<div className={less.extraMsg}>
                            <div><span className={less.tableLabel}>应用id：</span><span>{record.appid}</span></div>
                            <div><span className={less.tableLabel}>应用版本id：</span><span>{record._id}</span></div>
                            <div><span
                                className={less.tableLabel}>下载地址：</span><span>{record.url}</span>
                            </div>
                            <div><span
                                className={less.tableLabel}>ipfs下载地址：</span><span>{{
                                    1: <span style={{color:"#f0d200"}}>处理中...</span>,
                                    2: <span>{record.ipfshash}</span>,
                                    3: <span style={{color:"#ff4a3e"}}>处理失败</span>,
                            }[record.ipfsstatus]}</span>
                            </div>
                            <div><span className={less.tableLabel}>文件md5：</span><span>{record.md5}</span></div>
                        </div>)
                    }
                }
            },
            toolBar: this.getToolBar.bind(this),
            new: {
                defaultValue: '+新增版本',
                action: () => {
                    this.props.openTab({
                        title: '新增版本',
                        path: 'VersionAdd',
                        post: {
                            appid: this.props.post.appid,
                            parent: this.props,
                        }
                    })
                }
            }
        }
    }

    getToolBar() {
        return <div className={less.nameStyle}>应用名：{this.props.post.data.name}</div>
    }


    /**
     *  列表数据配置
     */
    getTableColumns() {
        log(this.powerConfig);
        return [
            {
                title: '版本号',
                dataIndex: 'version',
                key: 'version',
            },
            {
                title: '备注',
                dataIndex: 'descs',
                key: 'descs',
                render: (text) => {
                    return text ? <Popover content={text}><span>{text.length > 10?text.slice(0,10) + "...":text}</span></Popover> : '无'
                }
            },
            {
                title: '白名单',
                dataIndex: 'whitelist',
                key: 'whitelist',
                render: (text,record) => {
                    return text ? <Popover content={<div className={less.popDiv}>{text}</div>}>
                        {record.whiteType === 1
                          ?<span className={less.btnDetail} style={{color: "limegreen"}}>查看设备</span>
                          :<span className={less.btnDetail}>查看Rom</span>
                        }
                    </Popover> : '无'
                }
            },
            {
                title: '创建时间',
                dataIndex: 'addtime',
                key: 'addtime',
                render: (text) => {
                    return TimeHelp.getYMDHMS(text);
                }
            },
            {
                title: '更新时间',
                dataIndex: 'lasttime',
                key: 'lasttime',
                render: (text) => {
                    return TimeHelp.getYMDHMS(text, true);
                }
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render: (text) => {
                    return [<span style={{color: '#666666'}}>未启用</span>,<span style={{color: '#06ca62'}}>启用</span>,<span style={{color: '#ffda00'}}>灰度</span>][text]
                }
            },
            {
                title: '操作',
                width: 220,
                render: (text, record, index) => {
                    return (<div>
                        {/*<div*/}
                            {/*className={record.status == 1 ? less.btnDelete : less.btnEdit}*/}
                            {/*onClick={() => {*/}
                                {/*let newStatus = record.status == 0 ? 1 : 0;*/}
                                {/*Modal.confirm({*/}
                                    {/*title: '提示',*/}
                                    {/*content: <div>*/}
                                        {/*<span>是否 </span>*/}
                                        {/*{record.status == 1 ? <span style={{color: 'red'}}>禁用</span> :*/}
                                            {/*<span style={{color: 'green'}}>启用</span>}*/}
                                        {/*<span> {record.name} ?</span>*/}
                                    {/*</div>,*/}
                                    {/*onOk: () => {*/}
                                        {/*this.changeAccountStatus(record._id, newStatus)*/}
                                    {/*}*/}
                                {/*})*/}
                            {/*}}*/}
                        {/*>{record.status == 1 ? '禁用' : '启用'}*/}
                        {/*</div>*/}

                        {
                            this.powerConfig.Edit
                                ?<div
                                    className={less.btnEdit}
                                    onClick={() => {
                                        this.changeStatusModal(record);
                                    }}
                                >
                                    修改状态
                                </div>
                                :null
                        }
                        {
                            this.powerConfig.Edit
                                ?<div
                                    className={less.btnEdit}
                                    onClick={() => {
                                        this.props.openTab({
                                            title: '编辑版本',
                                            path: 'VersionAdd',
                                            post: {
                                                data: record,
                                                appid: this.props.post.appid,
                                                parent: this.props,
                                                navPath: 'ApplicationList',
                                            }
                                        })
                                    }}
                                >编辑
                                </div>
                                :null
                        }
                        {
                            this.powerConfig.Delete
                                ?<div
                                    className={less.btnDelete}
                                    onClick={() => {
                                        ModalDelete.show({
                                                title: "提示",
                                                okTitle: "确定",
                                                closeTitle: "取消",
                                            },
                                            {}
                                            ,
                                            {
                                                otherParam: {_id: record._id},
                                                content: record.name,
                                                url: APILXD.delAppVersion,
                                                apiType: 'post',
                                                tip: '删除后不可恢复',
                                                callBack: (state) => {
                                                    //删除成功回调
                                                    state === "success" && this._loadDataForNet();
                                                }
                                            });
                                    }}
                                >删除</div>
                                :null
                        }
                    </div>)
                }
            }
        ]
    }

    changeStatusModal(data){
        MB.show(
            {
                title: "修改版本状态",
                okTitle: "确定修改",
                closeTitle: "取消",
            },
            {
                colCount: 1,
                formItemLayout: {
                    labelCol: {span: 5},
                    wrapperCol: {span: 14, offset: 0},
                },
                parameterArr: [
                    {
                        type: "radio",
                        ver: true,
                        reg: (t)=>{
                            return true
                        },
                        required: true,
                        field: "status",
                        name: "状态",
                        verMessage: "请选择",
                        option: {
                            defaultValue: "" + data.status
                        },
                        data: [
                            {
                                title: <span className={less.radioLabel}>未启用</span>,
                                value: "0"
                            },
                            {
                                title: <span className={less.radioLabel}>启用</span>,
                                value: "1"
                            },
                            {
                                title: <span className={less.radioLabel}>灰度</span>,
                                value: "2"
                            },
                        ],
                        onChange: (value, state, changeState)=>{
                            console.log(value);
                            let newArr = state.parameterArr;
                            newArr.forEach((obj) => {
                                if(['tipTxt','checkVersion','checkVersion2','checkVersion3'].includes(obj.mark)){
                                    obj.field = value === "1" ? obj.mark : null;
                                }
                            });
                            changeState('parameterArr', newArr);
                        }
                    },
                    {
                        type: "input",
                        ver: true,
                        reg: (t)=>{
                            return true
                        },
                        mark: "tipTxt",
                        field: data && data.status == 1?"tipTxt":null,
                        purpose: "see",
                        name: "提示",
                        option: {
                            defaultValue: <div className={less.tipTxt}>您选择启用该版本，需要填写版本号来确认操作，请谨慎操作！！！</div>
                        },
                    },
                    {
                        type: "input",
                        ver: true,
                        reg: (t)=>{
                            return !!t
                        },
                        required: true,
                        mark: "checkVersion",
                        field: data && data.status == 1?"checkVersion":null,
                        name: "验证版本号",
                        option: {
                            placeholder: "请填写您需要启用版本的版本号",
                            defaultValue: null
                        },
                    },
                    {
                        type: "input",
                        ver: true,
                        reg: (t)=>{
                            return !!t
                        },
                        required: true,
                        mark: "checkVersion2",
                        field: data && data.status == 1?"checkVersion2":null,
                        name: "再次填写版本号",
                        option: {
                            placeholder: "请再次填写您需要启用版本的版本号",
                            defaultValue: null
                        },
                    },
                    {
                        type: "input",
                        ver: true,
                        reg: (t)=>{
                            return !!t
                        },
                        required: true,
                        mark: "checkVersion3",
                        field: data && data.status == 1?"checkVersion3":null,
                        name: "三次填写版本号",
                        option: {
                            placeholder: "请第三次填写您需要启用版本的版本号",
                            defaultValue: null
                        },
                    },
                ],
            },
            {
                otherParam: {_id: data._id},
                url: APILXD.setAppVersionStatus,
                beforeSubmit: (params) => {
                    if(params.status=="1" && (params.checkVersion !== data.version || params.checkVersion2 !== data.version || params.checkVersion3 !== data.version)){
                        message.error("版本号验证失败，请检查！")
                        return false
                    }


                    params.status = Number(params.status);
                    return true;
                },
                callBack: (state) => {
                    //添加成功回调
                    this._loadDataForNet();
                }
            });
    }

    changeVersionStatus(_id, status) {
        let param = {
            _id,
            status,
        };
        let successCB = (code, msg, json, option) => {
            this.setState({loading: false}, () => {
                message.success('操作成功');
                this._loadDataForNet();
            });
        };
        let failureCB = (code, msg) => {
            this.setState({loading: false}, () => {
                message.error(msg);
            });
        };

        this.setState({loading: true});

        HttpTool.post(APILXD.setAppVersionStatus, successCB, failureCB, param);
    }
}

module.exports = VersionList;