import React from 'react';
import ListPage from '../../base/ListPage.js';
import APILXD from '../../../http/APILXD.js';
import {Tooltip,Button,Popover,message,Modal} from 'antd';
import TimeHelp from "../../../tool/TimeHelp";
import LayoutAreaSelect from "../../../components/areaSelect/AreaSelectUseForLayout";
import moment from "moment";
import MonthSel from "../../../components/monthSel/MonthSel"
import InputWidthText from "../../../components/inputWidthText/InputWidthText"
import KeepTime from "../../../components/keepTime/KeepTime"
import HttpTool from "../../../tool/HttpTool";
import CookieHelp from "../../../tool/CookieHelp";

class DeviceStaticsList extends ListPage {
    constructor(props) {
        super(props);
    }

    base_getListConfig() {
        return {
            table: {
                columns: this.getTableColumns(),
                url: APILXD.getHeartbeatDeviceList,
            },
            search: {
                seniorSearch: this.getSeniorSearch(),
                option: {
                    openSenior: true,
                    placeholder: '请输入设备ID搜索',
                    reg: /^[\s\S]*$/,
                    beforeSubmit: (value) => {
                        if (value.areaData) {
                            if (value.areaData.countryId) {
                                value.countryId = value.areaData.countryId
                            }
                            // if (value.areaData.provinceId) {
                            //     value.provinceId = value.areaData.provinceId
                            // }
                            if (value.areaData.cityId) {
                                value.cityId = value.areaData.cityId
                            }
                        }
                        value.deviceId = value.keyword || null;
                        if(value.yyyymmData){
                            value.yyyymm = Number(value.yyyymmData.format("YYYYMM"))
                        }
                        if(value.millsData){
                            value.mills = value.millsData * 3600000
                        }
                        let minSize = this.getSizeData(value.minFreeSizeSet);
                        if(minSize){
                            value.minFreeSize = minSize
                        }
                        let maxSize = this.getSizeData(value.maxFreeSizeSet);
                        if(maxSize){
                            value.maxFreeSize = maxSize
                        }

                        if(maxSize && minSize && maxSize < minSize){
                            message.warning("剩余空间的配置的2个值相互矛盾");
                            return false
                        }
                        return true;
                    },
                }
            },
            toolBar: ()=>{
                return <div>
                    <Button
                        type={"primary"}
                        onClick={()=>{

                            let params = this.searchParam;
                            this.setState({
                                loading: true
                            },()=>{
                                HttpTool.post(APILXD.exportHeartbeatDeviceList,(code,msg,json)=>{
                                    this.setState({
                                        loading: false
                                    })
                                    if(json.key){
                                        //距离用户点击事件过去太久，会被拦截
                                        // window.downFileByUrl(json.key,"导出设备列表.xlsx")

                                        Modal.info({
                                            maskClosable: true,
                                            title: "文件生成成功，可点击下载",
                                            content: <div style={{wordBreak:"break-all"}}>
                                                <a href={json.key} target={"_black"}>{json.key}</a>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                <a href={json.key} target={"_black"}><Button
                                                    size={"small"}
                                                    type={"primary"}
                                                    ghost={true}
                                                >下载</Button></a>
                                            </div>
                                        })
                                    }else{
                                        message.warning("无可导出数据");
                                    }
                                },(code,msg)=>{
                                    message.error(msg)
                                    this.setState({
                                        loading: false
                                    })
                                },params)
                            })
                        }}
                    >
                        根据当前搜索条件导出设备
                    </Button>
                </div>
            }
        }
    }

    getSizeData(setConfig){
        if(!setConfig){
            return 0
        }
        return (setConfig[0] || 0) * 1024 * 1024 * 1024 + (setConfig[1] || 0) * 1024 * 1024 + (setConfig[2] || 0) * 1024
    }

    getSeniorSearch() {
        let userInfo = CookieHelp.getUserInfo();
        this.role = userInfo && userInfo.info && userInfo.info.role || 2;
        this.userRoms = userInfo && userInfo.info && userInfo.info.romIds || [];


        let defaultOption = {
            type: "input",
            ver: true,
            reg: /^[\S\s]{0,50}$/,
            verMessage: "最多50个字符",
            required: false,
        };
        return {
            colCount: 3,
            formItemLayout: {
                labelCol: {span: 6},
                wrapperCol: {span: 18, offset: 0},
            },
            parameterArr: [
                {
                    ...defaultOption,
                    field: "status",
                    name: "运行状态",
                    type: 'select',
                    selectType: 'value',
                    reg: (v) => {
                        return true;
                    },
                    option: {
                        defaultValue: {
                            key: '全部状态',
                            value: '',
                        },
                    },
                    data: [
                        {
                            title: '全部状态',
                            value: '',
                        },
                        {
                            title: <span style={{color: "red"}}>离线</span>,
                            value: 1,
                        },
                        {
                            title: <span style={{color: "darkorange"}}>TV中</span>,
                            value: 2,
                        },
                        {
                            title: <span style={{color: "limegreen"}}>可工作</span>,
                            value: 3,
                        },
                    ]
                },

                {
                    ...defaultOption,
                    field: "romIds",
                    name: "ROMID",
                    type: 'selectAll',
                    selectType: 'value',
                    reg: (v) => {
                        return true;
                    },
                    option: {
                        mode: "multiple",
                        dropdownMatchSelectWidth: false,
                        showSearch: true,
                        onSearch: ()=>{},
                        filterOption: (input, option) => {
                            return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        },
                        optionFilterProp: "children",
                    },
                    apiConfig: this.role === 1?{
                        url: APILXD.getHeartBeatRomIds,
                        fillObject: (json) => {
                            let data = [];
                            for (let obj of json) {
                                data.push({
                                    title: obj,
                                    value: obj,
                                });
                            }
                            return data;
                        }
                    }:null,

                    data: this.userRoms.map((v)=>{
                        return {title: v,value: v}
                    })
                },
                {
                    ...defaultOption,
                    field: "szSerialno",
                    name: "厂商设备ID",
                    type: 'Input',
                    reg: (v) => {
                        return true;
                    },
                    option: {

                    },
                },
                {
                    ...defaultOption,
                    field: "areaData",
                    name: "地区过滤",
                    reg: (v) => {
                        return !!v;
                    },
                    type: 'diy',
                    component: LayoutAreaSelect,
                    verMessage: "请选择",
                    option: {}
                },
                {
                    ...defaultOption,
                    field: "minFreeSizeSet",
                    name: "大于剩余空间",
                    reg: (v) => {
                        return !!v;
                    },
                    type: 'diy',
                    component: KeepTime,
                    verMessage: "请填写",
                    behindText01: "G",
                    behindText02: "MB",
                    behindText03: "KB",
                    option: {
                        placeholder: "请填写"
                    }
                },
                {
                    ...defaultOption,
                    field: "maxFreeSizeSet",
                    name: "小于剩余空间",
                    reg: (v) => {
                        return !!v;
                    },
                    type: 'diy',
                    component: KeepTime,
                    verMessage: "请填写",
                    behindText01: "G",
                    behindText02: "MB",
                    behindText03: "KB",
                    option: {
                        placeholder: "请填写"
                    }
                },
                {
                    ...defaultOption,
                    field: "hasPhysical",
                    name: "外设",
                    type: 'select',
                    selectType: 'value',
                    reg: (v) => {
                        return true;
                    },
                    option: {
                        defaultValue: {
                            key: '全部情况',
                            value: '',
                        },
                    },
                    data: [
                        {
                            title: '全部情况',
                            value: '',
                        },
                        {
                            title: '存在外设',
                            value: 1,
                        },
                        {
                            title: '不存在外设',
                            value: 2,
                        },
                    ]
                },
                {
                    ...defaultOption,
                    field: "yyyymmData",
                    name: "月份",
                    reg: (v) => {
                        return true;
                    },
                    type: 'diy',
                    component: MonthSel,
                    verMessage: "请选择",
                    option: {
                        allowClear:true,
                        format: "YYYY年MM月",
                        monthCellContentRender: (date, locale) => {
                            return <div>{date.month() + 1}月</div>
                        },
                        disabledDate: (currentDate) => {
                            return currentDate.valueOf() > Date.now().valueOf()
                        }
                    },
                    onChange: (value, state, changeState)=>{
                        console.log(value);
                        let newArr = state.parameterArr;
                        newArr.forEach((obj) => {
                            if (obj.mark === 'millsData') {
                                obj.field = value ? obj.mark : null;
                            }
                        });
                        changeState('parameterArr', newArr);
                    }
                },
                {
                    ...defaultOption,
                    mark: "millsData",
                    field: null,
                    name: "在线时长",
                    type: 'diy',
                    component: InputWidthText,
                    behindText: '小时以上',
                    verMessage: "请输入，仅支持数字",
                    reg: (v) => {
                        return v > 0
                    },
                    option: {
                        style: {width: "160px"},
                        max: 100000000,
                        min: 1,
                        precision: 0,
                    }
                },
            ],
        }
    }

    bytesToSize(bytes) {
        if (bytes === 0) return '0 B';

        let k = 1024;

        let sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        let i = Math.floor(Math.log(bytes) / Math.log(k));

        return (bytes / Math.pow(k, i)).toFixed(2) + ' ' + sizes[i];
    }


    /**
     *  列表数据配置
     */
    getTableColumns() {
        return [
            {
                title: 'ID',
                dataIndex: '_id',
                key: '_id',
                render: (text,record)=>{
                    return <div>
                        <div>设备ID：{text}</div>
                        <div>ROMID：{record.romId}</div>
                        <div>厂商设备ID：{record.szSerialno}</div>
                    </div>
                }
            },
            {
                title: '本月时长信息',
                dataIndex: 'duration',
                key: 'duration',
                render: (text, record, index) => {
                    let currentMonth;
                    if(record.monthTime && record.monthTime.length){
                        currentMonth = record.monthTime[0]
                    }else{
                        currentMonth = {
                            d: 0,
                            fd: 0,
                            pd: 0,
                            ym: 0,
                        }
                    }

                    return <Popover
                        title={"历史时长信息"}
                        content={<div>
                            <div>总时长：{this.getH(text)}</div>
                            <div>tv时长：{this.getH(record.playDuration)}</div>
                            <div>可工作时长：{this.getH(record.freeDuration)}</div>
                        </div>}
                    >
                        <div>
                            <div>总时长：{this.getH(currentMonth.d)}</div>
                            <div>tv时长：{this.getH(currentMonth.pd)}</div>
                            <div>可工作时长：{this.getH(currentMonth.fd)}</div>
                        </div>
                    </Popover>
                }
            },
            {
                title: '剩余空间',
                dataIndex: 'freeSize',
                key: 'freeSize',
                render: (text, record, index) => {
                    if(text === undefined){
                        return <span style={{color: "silver"}}>未知</span>
                    }
                    let color = "#000";
                    //小于1.5G
                    if(text < 1024 * 1024 * 1024 * 1.5){
                        color = "#ffeb48"
                    }
                    //小于500 MB
                    if(text < 1024 * 1024 * 500){
                        color = "#ff0000"
                    }

                    return  <span
                        style={{color: color}}
                    >
                        {this.bytesToSize(text)}
                    </span>
                }
            },
            {
                title: '外设',
                dataIndex: 'physical',
                key: 'physical',
                render: (text) => {
                    if(text=== null){
                        return <span style={{color: "#000"}}>不存在</span>
                    }

                    if(text=== undefined){
                        return <span style={{color: "silver"}}>未知</span>
                    }

                    return <span style={{color: "#000"}}>{text}</span>
                }
            },
            {
                title: '添加时间',
                dataIndex: 'addtime',
                key: 'addtime',
                render: (text, record, index) => {
                    return  <div>{record.addtime ? TimeHelp.getYMDHM(record.addtime) : "-"}</div>
                }
            },
            {
                title: '最后请求信息',
                dataIndex: 'location',
                key: 'location',
                render: (text, record, index) => {
                    let countryName = text && text.cname ? (text.cname + " ") : "";
                    let provinceName = text && text.pname ? (text.pname + " ") : "";
                    let cityName = text && text.ctname || "";
                    return <div>

                        <div>IP：{record.ip}</div>
                        <div>地点：{
                            countryName + provinceName + cityName
                        }
                        </div>
                    </div>
                }
            },
            {
                title: '最后请求时刻',
                dataIndex: 'lasttime',
                key: 'lasttime',
                render: (text,record) => {
                    return <div>
                        <div>最后请求：{text ? <Tooltip
                            title={TimeHelp.getYMDHM(text)}
                        >{moment(text).fromNow()}</Tooltip> : "-"}</div>
                        <div>最后TV：{record.lastPlayTime ? <Tooltip
                            title={TimeHelp.getYMDHM(record.lastPlayTime)}
                        >{moment(record.lastPlayTime).fromNow()}</Tooltip> : "-"}</div>
                        <div>最后可工作：{record.lastFreeTime ? <Tooltip
                            title={TimeHelp.getYMDHM(record.lastFreeTime)}
                        >{moment(record.lastFreeTime).fromNow()}</Tooltip> : "-"}</div>
                    </div>
                }
            },
            {
                title: '运行状态',
                dataIndex: 'status',
                key: 'status',
                render: (text) => {
                    let map = {
                        "1": {text: "离线", color: "red"},
                        "2": {text: "TV中", color: "darkorange"},
                        "3": {text: "可工作", color: "green"},
                    };
                    let statusMap = map[text + ""];
                    if (!statusMap) {
                        statusMap = {text: "未知", color: "red"}
                    }
                    return <span style={{color: statusMap.color}}>{statusMap.text}</span>
                }
            },

            {
                title: '操作',
                width: 120,
                render: (text, record, index) => {
                    return (
                        <div>
                            <Button
                                type={"link"}
                                onClick={()=>{
                                    this.props.openTab({
                                        path: 'DeviceStaticsDetail',
                                        title: '设备详情',
                                        post: {
                                            _id: record._id,
                                            parent: this.props
                                        }
                                    })
                                }}
                            >
                                查看设备详情
                            </Button>

                        </div>)
                }
            },
        ]
    }

    getH(t) {
        if (!t) {
            return "0 小时"
        }

        return <span>
            <span style={{color: "dodgerblue"}}>{(t / 3600000).toFixed(0)}</span>
            <span> 小时</span>
        </span>
    }
}

module.exports = DeviceStaticsList;