import React, {Component} from 'react';
import {Select, message, Icon} from 'antd';
import styles from "./AreaSelect.less";
import APILXD from '../../http/APILXD';
import HttpTool from "../../tool/HttpTool";
import {CountryAndCode} from "../../main/base/CountryAndCode";
const Option = Select.Option;


/**
 * 国家选择
 */
class AreaSelect extends Component {
    constructor(props) {
        super(props);

        //获取默认值
        this.initData = this.props.defaultValue;

        let matchCode;
        if(this.initData.countryId){
            CountryAndCode.forEach((item)=>{
                if(item.id === this.initData.countryId){
                    matchCode = item.code;
                }
            })
        }

        let countryValue = this.initData.countryId ? {
            id: this.initData.countryId,
            name: this.initData.countryName,
            code: matchCode || null
        } : undefined;

        this.state = {
            countrySel: true,        //国家选框是否可选
            countryData: [],        //国家下拉数据

            //已经选择的数据
            countryValue: countryValue,

            upDate: 0,
        }

        //记录每次编辑的数据
        this.param = {
            countryId: this.initData.countryId ? this.initData.countryId : undefined,
            countryName: this.initData.countryName ? this.initData.countryName : undefined,
        };
    }

    componentDidMount() {
        //用本地数据，不再请求
        // this.getSelectOptionData()
    }


    //值改变的回调，参数为选择的数据 obj
    valueChangeCB() {
        let {countryValue} = this.state;
        this.param = {
            countryId: countryValue ? countryValue.id : undefined,
            countryName: countryValue ? countryValue.name : undefined,
            phoneCode: countryValue ? countryValue.code : undefined,
        };

        this.props.onChange && this.props.onChange(this.param);
    }

    //验证输入
    verValue(config) {
        if (!config || !config.required) {
            //如果是非必填的，直接返回true,验证通过
            return true;
        }
        let {countryValue} = this.state;

        return !!countryValue;
    }


    /**
     *  请求数据
     */
    getSelectOptionData() {

        HttpTool.post(APILXD.getAllCountry, (code, msg, json) => {
            let c = [];
            json.forEach((countryItem) => {
                if (countryItem._id) {
                    c.push({
                        name: countryItem.name,
                        id: countryItem._id,
                        isLeaf: false,
                        type: 1
                    })
                }
            })
            this.setState({
                countryData: c
            })
        }, (code, msg) => {
            message.error(msg);
        }, {});
    }


    //渲染下拉选项
    getOptions(items) {
        let view = [];
        for (let key in items) {
            view.push(
                <Option
                    key={'key' + key}
                    value={items[key].id}
                    searchTxt={items[key].name + items[key].code}
                    name={items[key].name}
                    code={items[key].code}
                >
                    {items[key].name}
                    （{items[key].code}）
                </Option>
            );
        }

        return view;
    }

    render() {
        let {countryData,countryValue} = this.state;

        return (
            <div className={styles.container}>
                <Select
                  value={countryValue ? countryValue.id : undefined}
                  showSearch={true}
                  style={{width: "100%"}}
                  placeholder={"请选择地区与手机区号"}
                  optionFilterProp="children"
                  onSelect={(value, obj) => {
                      if (countryValue && value == countryValue.id) {
                          return;
                      }
                      let item = {
                          id: value,
                          name: obj.props.name,
                          code: obj.props.code
                      };


                      this.setState({
                          countryValue: item
                      },()=>{
                          this.valueChangeCB();
                      })
                  }}
                  filterOption={(input, option) => {
                      return option.props.searchTxt.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }}
                >
                    {this.getOptions(CountryAndCode)}
                </Select>
            </div>
        );
    }

}

AreaSelect.defaultProps = {
    defaultValue: {},
};

export default AreaSelect;