let APILXD = {
    /**
     * 通用
     */
    //上传文件
    upLoad: '/common/upload',
    //获取七牛token
    getUploadToken: '/admin/common/getUploadToken',
    //获取所有国家(与设备无关)
    getAllCountry: '/admin/common/getCountry',


    /**
     * 后台账号管理
     */
    //登陆
    login: '/admin/admin/login',
    //获取密码随机串
    getRandSalt: '/admin/admin/getRandSalt',
    //后台账号列表
    getAdminList: '/admin/admin/getAdminList',
    //查询详情
    getAdminDetail: '/admin/admin/getAdminDetail',
    //退出登陆
    logoutAdmin: '/admin/admin/logoutAdmin',
    //修改密码
    modfiyPasswd: '/admin/admin/modfiyPasswd',
    //启用、禁用管理员
    enableAdmin: '/admin/admin/enableAdmin',
    //新增
    addAdmin: '/admin/admin/addAdmin',
    //编辑
    editAdmin: '/admin/admin/editAdmin',
    //删除
    delAdmin: '/admin/admin/delAdmin',
    //获取自己信息
    getMyinfo: '/admin/admin/getMyinfo',
    //授权romids
    authRomIds: '/admin/admin/authRomIds',

    //公司管理
    companyIndex: '/admin/company/index',
    companySelect: '/admin/company/select',
    companyCreate: '/admin/company/create',
    companyUpdate: '/admin/company/update',
    companyDelete: '/admin/company/delete',

    //用户管理
    userIndex: '/admin/user/index',
    userCreate: '/admin/user/create',
    userUpdate: '/admin/user/update',
    userDelete: '/admin/user/delete',

    //获取设备列表
    getDeviceList: "/platform/admin/device/getDeviceList",
    //获取公司列表
    getSpaceList: "/admin/space/getSpaceList",
    //系统管理员获取公司列表
    getCompanyUserList: "/admin/user/getCompanyUserList",
    //获取rom列表
    getDeviceRomIdList: "/platform/admin/device/getDeviceRomIdList",
    //设备分配公司
    assignDeviceListToSpace: "/platform/admin/device/assignDeviceToCompany",
    //获取所有公司
    getAllSpaces: "/admin/company/select",
    //获取心跳设备的所有romId
    getHeartBeatRomIds: "/admin/deviceHeartbeat/getHeartBeatRomIds",

    //设备相关
    //批量添加及分配设备
    addDeviceList: "/admin/device/addDeviceList",
    //获取设备编码数组
    getDeviceIds: "/platform/admin/device/getDeviceIds",
    //获取分身配置列表
    getShardConfList: "/admin/conf/getShardConfList",
    //获取设备动态分配配置代码
    getDeviceDynamicAssignConf: "/admin/conf/getDeviceDynamicAssignConf",
    //设置设备动态分配配置代码
    setDeviceDynamicAssignConf: "/admin/conf/setDeviceDynamicAssignConf",
    //编辑分身配置
    editShardConf: "/admin/conf/editShardConf",
    //新增分身配置
    addShardConf: "/admin/conf/addShardConf",
    //获取设备列表（分身删除）
    shardDelDeviceGetDeviceList: "/admin/shardDelDevice/getDeviceList",
    //删除设备（分身删除）
    shardDelDeviceDelDevice: "/admin/shardDelDevice/delDevice",
    //新增设备（分身删除）
    shardDelDeviceAddDevice: "/admin/shardDelDevice/addDevice",
    //获取所有配置的盒子分身
    getBoxShard: "/admin/conf/getBoxShard",

    //应用管理
    //列表
    getAppsList: "/admin/app/getAppList",
    //新增应用
    addApp: "/admin/app/addApp",
    //编辑应用
    editApp: "/admin/app/editApp",
    //删除应用
    delApp: "/admin/app/delApp",
    //获取应用更新信息-用于测试
    getApps: "/admin/app/getApps",
    //应用版本
    //获取应用版本列表
    getAppVersionList: "/admin/appVersion/getAppVersionList",
    //新增应用版本
    addAppVersion: "/admin/appVersion/addAppVersion",
    //编辑应用版本
    editAppVersion: "/admin/appVersion/editAppVersion",
    //删除应用版本
    delAppVersion: "/admin/appVersion/delAppVersion",
    //设置应用版本状态
    setAppVersionStatus: "/admin/appVersion/setAppVersionStatus",
    //ipfs白名单
    ipfsGetDeviceList: "/admin/appIpfs/getDeviceList",
    //ipfs白名单
    ipfsDelDevice: "/admin/appIpfs/delDevice",
    //ipfs白名单
    ipfsAddDevice: "/admin/appIpfs/addDevice",

    //系统错误
    //获取系统日志列表
    getSyslogList: '/admin/syslog/getSyslogList',
    //删除系统日志
    delSyslog: '/admin/syslog/delSyslogs',

    //系统配置
    getAllGlobalConfigs: "/admin/globalConfig/getAllGlobalConfigs",
    setGlobalConfigs: "/admin/globalConfig/setGlobalConfigs",
    getPlanDistribLimit: "/admin/plan/getPlanDistribLimit",
    setPlanDistribLimit: "/admin/plan/setPlanDistribLimit",

    //获取设备实时总在线离线数
    getDeviceTotal: "/admin/deviceHeartbeat/getDeviceTotal",
    //获取设备每月在线达标数
    getDeviceMonthStandard: "/admin/deviceHeartbeat/getDeviceMonthStandard",
    //获取设备每日损耗情况
    getDeviceDayLoss: "/admin/deviceHeartbeat/getDeviceDayLoss",
    //获取最近30天新增设备
    getLatestThirtyAddDevice: "/admin/deviceHeartbeat/getLatestThirtyAddDevice",
    //获取超过30天未上线设备
    getOfflineThirtyDevice: "/admin/deviceHeartbeat/getOfflineThirtyDevice",
    //获取超过30天未上线设备
    getOnOfflineDeviceRate: "/admin/deviceHeartbeat/getOnOfflineDeviceRate",
    //获取当前设备位置分布
    getDeviceLocation: "/admin/deviceHeartbeat/getDeviceLocation",
    //获取设备列表
    getHeartbeatDeviceList: "/admin/deviceHeartbeat/getHeartbeatDeviceList",
    //获取设备详情
    getDevice: "/admin/deviceHeartbeat/getDevice",
    //获取设备几号到几号的每日在线时长
    getDeviceDayOnline: "/admin/deviceHeartbeat/getDeviceDayOnline",
    //获取设备某一天的心跳记录
    getDeviceDayHeartbeat: "/admin/deviceHeartbeat/getDeviceDayHeartbeat",
    //获取历史每天新增设备
    getLatestAllAddDevice: "/admin/deviceHeartbeat/getLatestAllAddDevice",
    //获取历史每天设备在线比
    getOnOfflineAllDeviceRate: "/admin/deviceHeartbeat/getOnOfflineAllDeviceRate",
    //导出设备
    exportHeartbeatDeviceList: "/admin/deviceHeartbeat/exportHeartbeatDeviceList",
    //获取设备区域分布数量统计
    getDeviceAreaTotalMsg: "/admin/deviceHeartbeat/getCountryDevice",
    //设备剩余空间
    getDeviceFreeSize: "/admin/deviceHeartbeat/getDeviceFreeSize",


    //仪表盘主题相关
    //获取主题列表
    getThemeList: "/admin/chartTheme/getThemeList",
    //获取主题详情
    getThemeDetail: "/admin/chartTheme/getThemeDetail",
    //新增主题
    addTheme: "/admin/chartTheme/addTheme",
    //编辑主题
    editTheme: "/admin/chartTheme/editTheme",
    //删除主题
    delTheme: "/admin/chartTheme/delTheme",
    //设置主题状态
    setThemeStatus: "/admin/chartTheme/setThemeStatus",
    //设置主题排序
    setThemeSort: "/admin/chartTheme/setThemeSort",

    //内置查询配置
    addBuiltInQuery: "/admin/chartBuiltInQuery/addBuiltInQuery",
    //编辑内置查询
    editBuiltInQuery: "/admin/chartBuiltInQuery/editBuiltInQuery",
    //删除内置查询
    delBuiltInQuery: "/admin/chartBuiltInQuery/delBuiltInQuery",
    //获取内置查询列表
    getBuiltInQueryList: "/admin/chartBuiltInQuery/getBuiltInQueryList",
    //设置内置查询排序
    setBuiltInQuerySort: "/admin/chartBuiltInQuery/setBuiltInQuerySort",
    //获取内置查询详情
    getBuiltInQuery: "/admin/chartBuiltInQuery/getBuiltInQuery",

    //ESIM管理
    //查询短信列表
    esimGetMessageList: "/platform/admin/getMessageList",
    //新增 esim
    esimInsert: "/platform/admin/insert",
    //修改 Esim 过期时间
    esimUpdate: "/platform/admin/update",
    //回收 Esim
    esimRecycle: "/platform/admin/recycleEsim",
    //查询esim列表
    esimGetESIMList: "/platform/admin/getESIMList",
    //发送验证码
    esimSendTestMessage: "/platform/admin/sendTestMessage",
    //获取平台下拉数据
    getPlatformList: "/platform/admin/getPlatformList",
    //统计
    esimDeviceStatistics: "/platform/admin/deviceStatistics",
    //手动绑定
    esimPreBind: "/platform/admin/preBind",


    //回收站
    //获取回收站列表
    esimGetRecycleEsimList: "/platform/admin/getRecycleEsimList",
    //Esim 还原
    esimRestore: "/platform/admin/restoreESim",
};

module.exports = APILXD;