import React from "react";
import less from "./DeviceStaticsDetail.less";
import {Spin, Modal, Col, Row, Table, Popover} from "antd";
import APILXD from "../../../http/APILXD";
import TimeHelp from "../../../tool/TimeHelp";
import CountView from './component/CountView.js';
import CountDayDetail from "./component/CountDayDetail.js"
import HttpTool from "../../../tool/HttpTool";

export default class DeviceStaticsDetail extends React.Component {
    constructor(props) {
        super(props);
        this.id = this.props.post._id;
        this.state = {
            data: null,
            loading: false
        }
    }

    componentDidMount() {
        this.getDevice()
    }

    /**
     * 统一的请求展示交互，目前是loading
     * @param path          请求地址
     * @param param         请求参数
     * @param successCB     成功的回调
     * @param failureCB     失败的回调
     * @param showAction    是否展示交互，默认打开
     */
    commonRequest(path, param, successCB, failureCB, showAction = true) {
        if (!showAction) {
            HttpTool.post(path, successCB, failureCB, param);
        } else {
            let newSuccessCB = (code, msg, obj, option) => {
                this.setState({
                    loading: false,
                }, () => {
                    successCB && successCB(code, msg, obj, option);
                })
            };
            let newFailureCB = (code, msg, obj, option) => {
                this.setState({
                    loading: false,
                }, () => {
                    failureCB && failureCB(code, msg, obj, option);
                })
            };

            this.setState({
                loading: true
            }, () => {
                HttpTool.post(path, newSuccessCB, newFailureCB, param);
            })
        }
    }

    getDevice() {
        this.commonRequest(APILXD.getDevice, {
            deviceId: this.id
        }, (code, msg, json) => {
            this.setState({
                data: json
            })
        }, (code, msg) => {
            Modal.error({
                title: "查询设备详情失败",
                content: msg
            })
        })
    }

    render() {
        const {data} = this.state;
        return (<div
            className={less.mainPage}
        >
            <Spin size={'large'} spinning={this.state.loading}>
                <Row>
                    <Col className={less.containerTitle}>
                        设备详情
                    </Col>
                </Row>
                <Row className={less.item}>
                    <Col span={4} className={less.label}>
                        设备ID：
                    </Col>
                    <Col span={8}>
                        {this.id}
                    </Col>
                    <Col span={8}>
                        ROMID：：
                        {data && data.romId}
                    </Col>
                </Row>
                <Row className={less.item}>
                    <Col span={4} className={less.label}>
                        运行状态：
                    </Col>
                    <Col span={8}>
                        {
                            data
                                ? <span style={{color: ['red', 'darkorange', 'limegreen'][data.status - 1]}}>
                            {data && data.status && ['离线', 'TV中', '可工作'][data.status - 1] || ''}
                        </span>
                                : null
                        }

                    </Col>
                </Row>
                <Row className={less.item}>
                    <Col span={4} className={less.label}>
                        创建时间：
                    </Col>
                    <Col span={16}>
                        {data && data.addtime && TimeHelp.getYMDHM(data.addtime) || ''}
                    </Col>
                </Row>
                <Row className={less.item}>
                    <Col span={4} className={less.label}>
                        最后请求时刻：
                    </Col>
                    {
                        data
                            ? <Col span={16}>
                                <div>最后请求: {data.lasttime ? TimeHelp.getYMDHM(data.lasttime, true) : "-"}</div>
                                <div>最后TV: {data.lastPlayTime ? TimeHelp.getYMDHM(data.lastPlayTime, true) : "-"}</div>
                                <div>最后可工作: {data.lastFreeTime ? TimeHelp.getYMDHM(data.lastFreeTime, true) : "-"}</div>
                            </Col>
                            : null
                    }

                </Row>
                <Row className={less.item}>
                    <Col span={4} className={less.label}>
                        <span>最后请求IP：</span>
                    </Col>
                    <Col span={16}>
                        {data && data.ip || ''}
                    </Col>
                </Row>
                <Row className={less.item}>
                    <Col span={4} className={less.label}>
                        <span>最后活跃地点：</span>
                    </Col>
                    <Col span={16}>
                        {data && data.location && data.location.cname || ''}
                        &nbsp; &nbsp;
                        {data && data.location && data.location.pname || ''}
                        &nbsp; &nbsp;
                        {data && data.location && data.location.ctname || ''}
                    </Col>
                </Row>
                <Row className={less.item}>
                    <Col span={4} className={less.label}>
                        时长信息：
                    </Col>
                    <Col span={20}>
                        {
                            data
                                ? <Row>
                                    <Col span={8}>总时长：{this.getH(data.duration)}</Col>
                                    <Col span={8}>总tv时长：{this.getH(data.playDuration)}</Col>
                                    <Col span={8}>总可工作时长：{this.getH(data.freeDuration)}</Col>
                                </Row>
                                : null
                        }
                    </Col>

                </Row>
                <Row>
                    <Col span={20} offset={2}>
                        {
                            data
                                ? <Table
                                    size={"small"}
                                    rowKey={"ym"}
                                    bordered={true}
                                    columns={[
                                        {
                                            title: '年月',
                                            dataIndex: 'ym',
                                            key: 'ym',
                                        },
                                        {
                                            title: '总在线时长',
                                            dataIndex: 'd',
                                            key: 'd',
                                            render: (text) => {
                                                return this.getH(text)
                                            }
                                        },
                                        {
                                            title: 'TV时长',
                                            dataIndex: 'pd',
                                            key: 'pd',
                                            render: (text) => {
                                                return this.getH(text)
                                            }
                                        },
                                        {
                                            title: '可工作时长',
                                            dataIndex: 'fd',
                                            key: 'fd',
                                            render: (text) => {
                                                return this.getH(text)
                                            }
                                        },
                                    ]}
                                    dataSource={data.monthTime}
                                />
                                : null
                        }
                    </Col>
                </Row>
                <Row>
                    <Col className={less.containerTitle}>
                        在线时长图
                    </Col>
                </Row>
                <Row className={less.item}>
                    <Col span={24}>
                        <CountView
                            id={this.id}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col className={less.containerTitle}>
                        单日心跳图
                    </Col>
                </Row>
                <Row className={less.item}>
                    <Col span={24}>
                        <CountDayDetail
                            id={this.id}
                        />
                    </Col>
                </Row>
            </Spin>
        </div>)
    }


    getH(t) {
        if (!t) {
            return "0 小时"
        }

        return <span>
            <span style={{color: "dodgerblue"}}>{(t / 3600000).toFixed(0)}</span>
            <span> 小时</span>
        </span>
    }
}