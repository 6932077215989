import React, {Component} from 'react';
import APILXD from '../../../http/APILXD.js';
import styles from "./UploadPackage.less";
import UploadHelp from '../../../components/upLoad/UploadHelp';
import Layout from '../../../components/layout/index';
import {message, Spin, Button, Row, Col,Modal} from 'antd';
import HttpTool from "../../../tool/HttpTool";

class UploadPackage extends Component {
    constructor(props) {
        super(props);
        this.upload = new UploadHelp();
        let params = this.props.post.type;
        this.state = {
            url: params && params.url || '',
            loading: false,
            upDate: 0,
            uploadingMsg: null
        }
    }

    render() {
        return (
            <div className={styles.mainPage}>
                <Spin spinning={this.state.loading} size={'large'}>
                    <Layout
                        key={'layout' + this.state.upDate}
                        ref={(ref) => {
                            this.baseMsgLayout = ref;
                        }}
                        {
                            ...this.getConfig(this.params)
                        }
                    />
                    <Row>
                        <Col span={5}>
                            <div className={styles.labelRequire}>
                                文件下载地址：
                            </div>
                        </Col>
                        <Col span={14}>
                            {
                                this.state.url
                                    ? (<div>
                                        <div
                                            className={styles.btnDelete}
                                            onClick={() => {
                                                this.setState({
                                                    url: null
                                                })
                                            }}
                                        >删除
                                        </div>
                                        <div>{this.state.url}</div>
                                    </div>)
                                    : (<div>
                                        {
                                            this.state.uploadingMsg
                                                ? (<div>
                                                    <div>
                                                        <span>上传进展：&nbsp;</span>
                                                        <span>{this.state.uploadingMsg.loaded}</span>
                                                        <span>&nbsp;/&nbsp;</span>
                                                        <span>{this.state.uploadingMsg.size}</span>
                                                    </div>
                                                    <div className={styles.progressOut}>
                                                        <div
                                                            style={{
                                                                width: (100 - this.state.uploadingMsg.percent) + '%'
                                                            }}
                                                            className={styles.progressIn}
                                                        />
                                                    </div>
                                                </div>)
                                                : <input
                                                    type="file"
                                                    onChange={(e) => {
                                                        let targetFile = e.target.files[0];
                                                        log(targetFile);

                                                        this.toUploadFile(targetFile);
                                                    }}
                                                />
                                        }
                                    </div>)
                            }
                        </Col>
                    </Row>
                    <div
                        className={styles.btnBox}
                    >
                        <Button
                            size={'large'}
                            className={styles.btn}
                            onClick={() => {
                                this.props.openTab(this.props.post.parent);
                                this.props.closeTab(this.props.targetKey);
                            }}
                        >取消</Button>
                        <Button
                            disabled={!this.state.url}
                            type={'primary'}
                            size={'large'}
                            className={styles.btn}
                            onClick={() => {
                                this.clickAddBtn();
                            }}
                        >{this.id ? '保存' : '添加'}</Button>
                    </div>
                </Spin>
            </div>
        )
    }

    //配置
    getConfig(data) {
        let defaultOption = {
            type: "input",
            ver: true,
            reg: /^[a-zA-Z0-9.]{1,32}$/,
            verMessage: "1-32位数字或英文字母",
            required: true,
        };
        return {
            colCount: 1,
            formItemLayout: {
                labelCol: {span: 5},
                wrapperCol: {span: 14, offset: 0},
            },
            parameterArr: [
                {
                    ...defaultOption,
                    field: "descs",
                    name: "备注",
                    type: 'TextArea',
                    verMessage: "请输入备注",
                    required: false,
                    reg: (v) => {
                        return v && v.length >= 1 && v.length <= 256;
                    },
                    option: {
                        placeholder: '请输入备注，1-256个字',
                        maxLength: '256',
                        autosize: {minRows: 6, maxRows: 10},
                        defaultValue: data ? data.descs : ''
                    }
                },
            ],
        }
    }

    toUploadFile(file) {
        log(file);
        let suffixList = file && file.name && file.name.split('.');
        if (suffixList.length <= 1) {
            message.warning('文件选择出错');
            return;
        }
        let suffix = suffixList[suffixList.length - 1];
        if (!suffix) {
            message.warning('文件选择出错');
            return;
        }
        let successCB = (code, msg, json, option) => {
            this.setState({
                url: json.url,
                uploadingMsg: null,
            })
        };
        let failureCB = (code, msg) => {
            this.setState({
                uploadingMsg: null,
            }, () => {
                message.error('上传失败')
            });
        };
        let loadingCB = (res) => {
            if (res && res.total) {
                this.setState({
                    uploadingMsg: res.total
                })
            }
        };

        this.setState({
            upLoading: true,
        }, () => {
            this.upload.upload(file, suffix, successCB, failureCB, loadingCB);
        });
    }

    //点击创建完成按钮
    clickAddBtn() {
        let option = this.baseMsgLayout.getLayoutValue(true);
        if (option.error) {
            message.warning('请完善表单信息');
            return;
        }
        if (!this.state.url) {
            message.warning('请上传文件');
            return;
        }
        let data = option.parameter;

        data.url = this.state.url;

        Modal.confirm({
            title: '提示',
            content: '是否确认提交？',
            onOk: () => {
                this.doSubmit(data);
            }
        })
    }

    //提交数据
    doSubmit(data) {
        let param = data;
        if (this.appid) {
            param.appid = this.appid;
        }
        if (this.id) {
            param.id = this.id;
        }
        log(param)
        let successCB = (code, msg, json, option) => {
            this.setState({loading: false}, () => {
                message.success('修改成功');
                this.props.openTab(this.props.post.parent);
                this.props.closeTab(this.props.targetKey);
            });
        };
        let failureCB = (code, msg) => {
            this.setState({loading: false}, () => {
                message.error(msg);
            });
        };

        this.setState({loading: true});
        let url = this.id ? APILXD.editAppVersion : APILXD.addAppVersion;
        HttpTool.post(url, successCB, failureCB, param);
    }

}

module.exports = UploadPackage;