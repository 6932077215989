import React from 'react';
import ListPage from '../../base/ListPage.js';
import APILXD from '../../../http/APILXD.js';
import {Modal, message} from 'antd';
import TimeHelp from "../../../tool/TimeHelp";
import HttpTool from "../../../tool/HttpTool";
import ModalBase from "../../../components/modalBase/index";
import styles from './DeviceList.less';

const MB = new ModalBase();

class SeparationList extends ListPage {
    constructor(props) {
        super(props);
    }

    base_getListConfig() {
        return {
            table: {
                columns: this.getTableColumns(),
                url: APILXD.getShardConfList,
                otherParam: null,
            },
            new: {
                defaultValue: '添加分身',
                action: () => {
                    this.add();
                }
            },
        }
    }

    getAddData(data, disabled = false) {
        let option = {};
        if (data && data.status) {
            option = {
                defaultValue: {
                    key: data.status,
                    value: data.status
                }
            }
        }
        let props = {
            colCount: 1,
            formItemLayout: {
                labelCol: {span: 6},
                wrapperCol: {span: 12, offset: 0},
            },
            parameterArr: [
                {

                    field: "shard",
                    name: '分身名称',
                    required: "true",
                    type: "input",
                    ver: true,
                    reg: /^([\u4E00-\uFA29]|[\uE7C7-\uE7F3]|[a-zA-Z0-9_]){1,32}$/,
                    verMessage: "请输入正确的分身名",
                    option: {
                        placeholder: '请输入唯一分身名，最多不超过32个字符',
                        maxLength: 32,
                        disabled: disabled,
                        defaultValue: data && data.shard
                    }
                },
                {
                    field: "descs",
                    name: '备注',
                    type: 'TextArea',
                    reg: /^[\s\S]{0,250}$/,
                    required: false,
                    option: {
                        placeholder: '请输入备注，最多不超过250个字符',
                        maxLength: '250',
                        autosize: {minRows: 4, maxRows: 6},
                        defaultValue: data && data.descs
                    }
                },

                {
                    field: "status",
                    name: "是否启用分身",
                    reg: (v) => {
                        return !!v;
                    },
                    verMessage: "请选择",
                    type: 'Select',
                    selectType: 'value',
                    required: "true",
                    ver: true,
                    data: [
                        {
                            title: '启用',
                            value: 1,
                        },
                        {
                            title: '禁用',
                            value: 2,
                        },
                    ],
                    option: option
                }
            ],
        };
        return {
            props,
            otherParam: null
        }
    }

    change(data) {
        let addData = this.getAddData(data, true);
        MB.show(
            {
                title: "修改分身",
                okTitle: "修改",
                closeTitle: "取消",
            },
            addData.props
            ,
            {
                url: APILXD.editShardConf,
                beforeSubmit: (param) => {
                    param.code = _.trim(param.code);
                    param._id = data._id;
                    if (typeof param.status !== "number") {
                        if (param.status && param.status.key) {
                            param.status = Number(param.status.key)
                        } else {
                            Number(param.status)
                        }
                    }
                    return true;
                },
                callBack: (state, msg) => {
                    //添加成功回调
                    state == 'success' && this._loadDataForNet();
                }
            });
    }

    add() {
        let addData = this.getAddData({});
        MB.show(
            {
                title: "添加分身",
                okTitle: "新增",
                closeTitle: "取消",
            },
            addData.props
            ,
            {
                url: APILXD.addShardConf,
                beforeSubmit: (param) => {
                    param.name = _.trim(param.name);
                    param.code = _.trim(param.code);
                    return true;
                },
                callBack: (state, msg) => {
                    //添加成功回调
                    state == 'success' && this._loadDataForNet();
                }
            });
    }

    getSeniorSearch() {
        return {
            colCount: 3,
            formItemLayout: {
                labelCol: {span: 6},
                wrapperCol: {span: 18, offset: 0},
            },
            parameterArr: [
                {
                    field: "spaceIds",
                    name: "公司名称",
                    type: 'select',
                    selectType: 'value',
                    reg: (v) => {
                        return !!v;
                    },
                    apiConfig: {
                        url: APILXD.getSpaceList,
                        fillObject: (json) => {
                            let data = [];
                            for (let obj of json) {
                                data.push({
                                    title: obj.name,
                                    value: obj._id,
                                });
                            }
                            data.unshift({
                                title: '全部公司',
                                value: '',
                            });

                            return data;
                        }
                    },
                    option: {
                        dropdownMatchSelectWidth: false,
                        showSearch: true,
                        onSearch: () => {},
                        placeholder: "输入名称可以精确匹配",
                        filterOption: (input, option) => {
                            return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        },
                        optionFilterProp: "children",
                        defaultValue: {
                            key: '',
                            value: '',
                            title: '全部公司'
                        },
                    },
                    data: []
                },
            ],
        }
    }

    changeAccountStatus(id, status) {
        let param = {
            id,
            status,
        };
        let successCB = (code, msg, json, option) => {
            this.setState({loading: false}, () => {
                message.success('操作成功');
                this._loadDataForNet();
            });
        };
        let failureCB = (code, msg) => {
            this.setState({loading: false}, () => {
                message.error(msg);
            });
        };

        this.setState({loading: true});

        HttpTool.post(APILXD.enableAdmin, successCB, failureCB, param);
    }


    /**
     *  列表数据配置
     */
    getTableColumns() {
        return [
            {
                title: '分身名',
                dataIndex: 'shard',
                key: 'shard',
            },
            {
                title: '描述',
                dataIndex: 'descs',
                key: 'descs',
            },
            {
                title: '添加时间',
                dataIndex: 'addtime',
                key: 'addtime',
                render: (text) => {
                    let time = TimeHelp.getYMDHMS(text);
                    log("time", time);
                    return <div>{time}</div>
                }
            },
            {
                title: '最后修改时间',
                dataIndex: 'lasttime',
                key: 'lasttime',
                render: (text) => {
                    let time = TimeHelp.getYMDHMS(text);
                    log("time", time);
                    return <div>{time}</div>
                }
            },
            {
                title: '分身状态',
                dataIndex: 'status',
                key: 'status',
                render: (text) => {
                    let map = {
                        1: {text: "开启", color: "green"},
                        2: {text: "禁用", color: "red"},
                    };
                    let statuss = map [text];
                    if (!statuss) {
                        statuss = {text: "未知"}
                    }
                    return <div style={{color: statuss.color}}>{statuss.text}</div>
                }
            },
            {
                title: '操作',
                width: 120,
                render: (text, record, index) => {
                    return this.powerConfig.Edit?<div onClick={() => {
                        this.change(record);
                    }} className={styles.clickable}>{"编辑"}</div>
                        :null
                }
            },
        ]
    }
}

module.exports = SeparationList;