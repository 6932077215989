import React from 'react';
import ListPage from '../../base/ListPage.js';
import APILXD from '../../../http/APILXD.js';
import {Modal, message, Tag,Button} from "antd";
import TimeHelp from "../../../tool/TimeHelp";
import HttpTool from "../../../tool/HttpTool";

const MessageStatusMap = {
  1: {
    title: "已读",
    value: 1,
    color: "dodgerblue",
  },
  2: {
    title: "未读",
    value: 2,
    color: "silver",
  },
}
class ESIMMessageList extends ListPage {
  constructor(props) {
    super(props);
  }

  base_getListConfig() {
    return {
      table: {
        columns: this.getTableColumns(),
        url: APILXD.esimGetMessageList,
        otherParam: {
          mobile: this.props.post.mobile
        }
      },
      search: {
        seniorSearch: this.getSeniorSearch(),
        option: {
          hideInput: true,
          hideSeniorBtn: true,
          openSenior: true,
          placeholder: '请输入手机号码模糊搜索',
          reg: /^[\s\S]*$/,
          beforeSubmit: (value) => {
            return true;
          },
        }
      },
      toolBar: ()=>{
        return <div
          style={{marginBottom: "10px"}}
        >
          <span>手机号：{this.props.post && this.props.post.mobile}</span>
          {
            this.powerConfig.Edit && [3,6].includes(this.props.post.status)
              ?<Button
                type={"primary"}
                size={"small"}
                style={{marginLeft: "20px"}}
                onClick={()=>{
                  this.sendCode()
                }}
              >发送验证码</Button>
              :null
          }
        </div>
      }
    }
  }

  sendCode(){
    Modal.confirm({
      title: "提示",
      content: "是否发送一条验证码给手机号：" + this.props.post.mobile + " ？",
      okText: "发送",
      onOk: ()=>{
        HttpTool.post(APILXD.esimSendTestMessage,(code,msg,json)=>{
          message.success(msg);
        },(code,msg)=>{
          message.error(msg)
        },{
          mobile: this.props.post.mobile
        })
      }
    })
  }

  getSeniorSearch() {
    return {
      colCount: 3,
      formItemLayout: {
        labelCol: {span: 6},
        wrapperCol: {span: 18, offset: 0},
      },
      parameterArr: [
        {
          field: "platform",
          name: "平台",
          type: 'select',
          selectType: 'value',
          required: false,
          ver: true,
          verMessage: "请选择平台",
          reg: (v) => {
            return true
          },
          apiConfig: {
            url: APILXD.getPlatformList,
            fillObject: (json) => {
              let data = [];
              for (let code in json) {
                data.push({
                  title: json[code],
                  value: code
                });
              }

              return data;
            }
          },
          data: [],
          option: {
            allowClear: true,
            placeholder: "请选择平台",
            dropdownMatchSelectWidth: false,
            showSearch: true,
            onSearch: () => {},
            filterOption: (input, option) => {
              return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            optionFilterProp: "children",
          }
        },
        {
          field: "status",
          name: "状态",
          type: 'select',
          selectType: 'value',
          reg: (v) => {
            return true;
          },
          option: {
            allowClear: true,
          },
          data: Object.values(MessageStatusMap).map((item)=>{
            return {
              title: <Tag color={item.color}>{item.title}</Tag>,
              value: item.value
            }
          })
        },
      ],
    }
  }

  /**
   *  列表数据配置
   */
  getTableColumns() {
    return [
      {
        title: '短信内容',
        dataIndex: 'content',
        key: 'content',
        width: 600
      },
      {
        title: '平台',
        dataIndex: 'platformName',
        key: 'platformName',
        render: (text)=> {
          if(!text){
            return "-"
          }
          return text
        }
      },
      {
        title: '收到时间',
        dataIndex: 'recycleTime',
        key: 'recycleTime',
        render: (text, record, index) => {
          return  <div>{record.sendTime ? TimeHelp.getYMDHMS(record.sendTime,true) : "-"}</div>
        }
      },
      {
        title: '状态',
        dataIndex: 'status',
        key: 'status',
        render: (text)=> {
          if(!MessageStatusMap[text]){
            return "-"
          }
          return <Tag color={MessageStatusMap[text].color}>{MessageStatusMap[text].title}</Tag>
        }
      },
    ]
  }
}

module.exports = ESIMMessageList;