import React from 'react';
import less from './TestManage.less';
import {message} from "antd/lib/index";
import APILXD from "../../../../http/APILXD";
import {Spin, Button, Modal, Row, Col} from 'antd';
import HttpTool from "../../../../tool/HttpTool";
import Layout from "../../../../components/layout/index";

class RomAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            returnData: null,
            upDate: 0,
        }
    }

    //配置
    getConfig(data) {
        let defaultOption = {
            type: "input",
            ver: true,
            reg: /^[\s\S]{1,32}$/,
            verMessage: "1-32个字符",
            required: true,
        };
        return {
            colCount: 1,
            formItemLayout: {
                labelCol: {span: 5},
                wrapperCol: {span: 14, offset: 0},
            },
            parameterArr: [
                {
                    field: "tag",
                    name: "接口地址",
                    option: {
                        defaultValue: '/admin/app/getApps',
                    }
                },
                {
                    ...defaultOption,
                    field: "deviceId",
                    name: "设备id",
                    reg: /[\s\S]{1,50}$/,
                    verMessage: "请输入",
                    option: {
                        placeholder: '请输入设备id',
                        maxLength: '50',
                    }
                },
                {
                    ...defaultOption,
                    field: "romId",
                    name: "romId",
                    type: 'select',
                    selectType: 'value',
                    reg: (v) => {
                        return true;
                    },
                    required: false,
                    verMessage: "请选择需要测试的RomID",
                    apiConfig: {
                        url: APILXD.getDeviceRomIdList,
                        fillObject: (json) => {
                            let data = [];
                            for (let obj of json) {
                                data.push({
                                    title: obj,
                                    value: obj,
                                });
                            }
                            return data;
                        }
                    },
                    option: {
                        allowClear: true,
                        dropdownMatchSelectWidth: false,
                        showSearch: true,
                        onSearch: ()=>{},
                        filterOption: (input, option) => {
                            return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        },
                        optionFilterProp: "children",
                        defaultValue: (data && data.whitelist) ? data.whitelist.split(",").map((t)=>{return {
                            key: t,
                            value: t
                        }}) : undefined
                    },
                    data: []
                },
            ],
        }
    }

    render() {
        return (<div className={less.mainPage}>
            <Spin spinning={this.state.loading} size={'large'}>
                <Row>
                    <Col className={less.containerTitle}>
                        测试接口
                    </Col>
                </Row>
                <Layout
                    key={'layout' + this.state.upDate}
                    ref={(ref) => {
                        this.baseMsgLayout = ref;
                    }}
                    {
                        ...this.getConfig(this.params)
                    }
                />
                <div
                    className={less.btnBox}
                    style={{marginBottom: "150px"}}
                >
                    <Button
                        size={'large'}
                        className={less.btn}
                        onClick={() => {
                            this.props.closeTab(this.props.targetKey);
                        }}
                    >取消</Button>
                    <Button
                        type={'primary'}
                        size={'large'}
                        className={less.btn}
                        onClick={() => {
                            this.clickTest();
                        }}
                    >发送</Button>
                </div>
                <Row>
                    <Col className={less.containerTitle}>
                        返回结果
                    </Col>
                </Row>
                <Row>
                    <Col span={20} offset={2}>
                        <div
                            dangerouslySetInnerHTML={{__html: this.getReq(this.state.returnData)}}
                        ></div>
                    </Col>
                </Row>
            </Spin>
        </div>)
    }

    getReq(obj) {
        if (!obj) {
            return ""
        }
        return '<pre>' + this.syntaxHighlight(JSON.stringify(obj, undefined, 2)) + '</pre>';
    }

    syntaxHighlight(json) {
        json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
        return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, (match) => {
            var cls = less.numberType;
            if (/^"/.test(match)) {
                if (/:$/.test(match)) {
                    cls = less.keyType;
                } else {
                    cls = less.stringType;
                }
            } else if (/true|false/.test(match)) {
                cls = less.booleanType;
            } else if (/null/.test(match)) {
                cls = less.nullType;
            }
            return '<span class="' + cls + '">' + match + '</span>';
        });
    }

    //点击发送按钮
    clickTest() {
        let option = this.baseMsgLayout.getLayoutValue(true);
        if (option.error) {
            message.warning('请完善表单信息');
            return;
        }
        let data = option.parameter;

        let params = {
            deviceId: data.deviceId,
            romId: data.romId || null,
        };

        let successCB = (code, msg, json, option) => {
            this.setState({
                loading: false,
                returnData: json,
            }, () => {
                message.success(msg);
            });
        };
        let failureCB = (code, msg) => {
            this.setState({loading: false}, () => {
                message.error(msg);
            });
        };

        this.setState({loading: true}, () => {
            HttpTool.post(APILXD.getApps, successCB, failureCB, params);
        });
    }
}

module.exports = RomAdd;