const CountryAndCode = [
  { "id": "CN", "name": "中国", "code": "+86" },
  { "id": "HK", "name": "中国香港", "code": "+852" },
  { "id": "MO", "name": "中国澳门", "code": "+853" },
  { "id": "TW", "name": "中国台湾", "code": "+886" },
  { "id": "GB", "name": "英国", "code": "+44" },
  { "id": "US", "name": "美国", "code": "+1" },
  { "id": "BY", "name": "白俄罗斯", "code": "+375" },
  { "id": "AU", "name": "澳大利亚", "code": "+61" },
  { "id": "CU", "name": "古巴", "code": "+53" },
  { "id": "AF", "name": "阿富汗", "code": "+93" },
  { "id": "FR", "name": "法国", "code": "+33" },
  { "id": "IN", "name": "印度", "code": "+91" },
  { "id": "DE", "name": "德国", "code": "+49" },
  { "id": "JP", "name": "日本", "code": "+81" },
  { "id": "KR", "name": "韩国", "code": "+82" },
  { "id": "IT", "name": "意大利", "code": "+39" },
  { "id": "BR", "name": "巴西", "code": "+55" },
  { "id": "AL", "name": "阿尔巴尼亚", "code": "+355" },
  { "id": "DZ", "name": "阿尔及利亚", "code": "+213" },
  { "id": "AS", "name": "美属萨摩亚", "code": "+1-684" },
  { "id": "AD", "name": "安道尔", "code": "+376" },
  { "id": "AO", "name": "安哥拉", "code": "+244" },
  { "id": "AI", "name": "安圭拉", "code": "+1-264" },
  { "id": "AQ", "name": "南极洲", "code": "+672" },
  { "id": "AG", "name": "安提瓜和巴布达", "code": "+1-268" },
  { "id": "AR", "name": "阿根廷", "code": "+54" },
  { "id": "AM", "name": "亚美尼亚", "code": "+374" },
  { "id": "AW", "name": "阿鲁巴", "code": "+297" },
  { "id": "AT", "name": "奥地利", "code": "+43" },
  { "id": "AZ", "name": "阿塞拜疆", "code": "+994" },
  { "id": "BS", "name": "巴哈马", "code": "+1-242" },
  { "id": "BH", "name": "巴林", "code": "+973" },
  { "id": "BD", "name": "孟加拉国", "code": "+880" },
  { "id": "BB", "name": "巴巴多斯", "code": "+1-246" },
  { "id": "BE", "name": "比利时", "code": "+32" },
  { "id": "BZ", "name": "伯利兹", "code": "+501" },
  { "id": "BJ", "name": "贝宁", "code": "+229" },
  { "id": "BM", "name": "百慕大", "code": "+1-441" },
  { "id": "BT", "name": "不丹", "code": "+975" },
  { "id": "BO", "name": "玻利维亚", "code": "+591" },
  { "id": "BA", "name": "波斯尼亚和黑塞哥维那", "code": "+387" },
  { "id": "BW", "name": "博茨瓦纳", "code": "+267" },
  { "id": "BV", "name": "布韦岛", "code": "+47" },
  { "id": "IO", "name": "英属印度洋领地", "code": "+246" },
  { "id": "BN", "name": "文莱", "code": "+673" },
  { "id": "BG", "name": "保加利亚", "code": "+359" },
  { "id": "BF", "name": "布基纳法索", "code": "+226" },
  { "id": "BI", "name": "布隆迪", "code": "+257" },
  { "id": "KH", "name": "柬埔寨", "code": "+855" },
  { "id": "CM", "name": "喀麦隆", "code": "+237" },
  { "id": "CV", "name": "佛得角", "code": "+238" },
  { "id": "KY", "name": "开曼群岛", "code": "+1-345" },
  { "id": "CF", "name": "中非共和国", "code": "+236" },
  { "id": "TD", "name": "乍得", "code": "+235" },
  { "id": "CL", "name": "智利", "code": "+56" },
  { "id": "CX", "name": "圣诞岛", "code": "+61" },
  { "id": "CC", "name": "科科斯（基林）群岛", "code": "+61" },
  { "id": "CO", "name": "哥伦比亚", "code": "+57" },
  { "id": "KM", "name": "科摩罗", "code": "+269" },
  { "id": "CG", "name": "刚果（布）", "code": "+242" },
  { "id": "CD", "name": "刚果（金）", "code": "+243" },
  { "id": "CK", "name": "库克群岛", "code": "+682" },
  { "id": "CR", "name": "哥斯达黎加", "code": "+506" },
  { "id": "CI", "name": "科特迪瓦", "code": "+225" },
  { "id": "HR", "name": "克罗地亚", "code": "+385" },
  { "id": "CY", "name": "塞浦路斯", "code": "+357" },
  { "id": "CZ", "name": "捷克共和国", "code": "+420" },
  { "id": "DK", "name": "丹麦", "code": "+45" },
  { "id": "DJ", "name": "吉布提", "code": "+253" },
  { "id": "DM", "name": "多米尼克", "code": "+1-767" },
  { "id": "DO", "name": "多米尼加共和国", "code": "+1-809" },
  { "id": "EC", "name": "厄瓜多尔", "code": "+593" },
  { "id": "EG", "name": "埃及", "code": "+20" },
  { "id": "SV", "name": "萨尔瓦多", "code": "+503" },
  { "id": "GQ", "name": "赤道几内亚", "code": "+240" },
  { "id": "ER", "name": "厄立特里亚", "code": "+291" },
  { "id": "EE", "name": "爱沙尼亚", "code": "+372" },
  { "id": "ET", "name": "埃塞俄比亚", "code": "+251" },
  { "id": "FK", "name": "福克兰群岛", "code": "+500" },
  { "id": "FO", "name": "法罗群岛", "code": "+298" },
  { "id": "FJ", "name": "斐济", "code": "+679" },
  { "id": "FI", "name": "芬兰", "code": "+358" },
  { "id": "GF", "name": "法属圭亚那", "code": "+594" },
  { "id": "PF", "name": "法属波利尼西亚", "code": "+689" },
  { "id": "TF", "name": "法属南部领地", "code": "+262" },
  { "id": "GA", "name": "加蓬", "code": "+241" },
  { "id": "GM", "name": "冈比亚", "code": "+220" },
  { "id": "GE", "name": "格鲁吉亚", "code": "+995" },
  { "id": "GH", "name": "加纳", "code": "+233" },
  { "id": "GI", "name": "直布罗陀", "code": "+350" },
  { "id": "GR", "name": "希腊", "code": "+30" },
  { "id": "GL", "name": "格陵兰", "code": "+299" },
  { "id": "GD", "name": "格林纳达", "code": "+1-473" },
  { "id": "GP", "name": "瓜德罗普", "code": "+590" },
  { "id": "GU", "name": "关岛", "code": "+1-671" },
  { "id": "GT", "name": "危地马拉", "code": "+502" },
  { "id": "GG", "name": "根西岛", "code": "+44-1481" },
  { "id": "GN", "name": "几内亚", "code": "+224" },
  { "id": "GW", "name": "几内亚比绍", "code": "+245" },
  { "id": "GY", "name": "圭亚那", "code": "+592" },
  { "id": "HT", "name": "海地", "code": "+509" },
  { "id": "HM", "name": "赫德岛和麦克唐纳群岛", "code": "+ " },
  { "id": "VA", "name": "梵蒂冈", "code": "+379" },
  { "id": "HN", "name": "洪都拉斯", "code": "+504" },
  { "id": "HU", "name": "匈牙利", "code": "+36" },
  { "id": "IS", "name": "冰岛", "code": "+354" },
  { "id": "ID", "name": "印度尼西亚", "code": "+62" },
  { "id": "IR", "name": "伊朗", "code": "+98" },
  { "id": "IQ", "name": "伊拉克", "code": "+964" },
  { "id": "IE", "name": "爱尔兰", "code": "+353" },
  { "id": "IM", "name": "马恩岛", "code": "+44-1624" },
  { "id": "IL", "name": "以色列", "code": "+972" },
  { "id": "JM", "name": "牙买加", "code": "+1-876" },
  { "id": "JE", "name": "泽西岛", "code": "+44-1534" },
  { "id": "JO", "name": "约旦", "code": "+962" },
  { "id": "KZ", "name": "哈萨克斯坦", "code": "+7" },
  { "id": "KE", "name": "肯尼亚", "code": "+254" },
  { "id": "KI", "name": "基里巴斯", "code": "+686" },
  { "id": "KP", "name": "朝鲜", "code": "+850" },
  { "id": "KW", "name": "科威特", "code": "+965" },
  { "id": "KG", "name": "吉尔吉斯斯坦", "code": "+996" },
  { "id": "LA", "name": "老挝", "code": "+856" },
  { "id": "LV", "name": "拉脱维亚", "code": "+371" },
  { "id": "LB", "name": "黎巴嫩", "code": "+961" },
  { "id": "LS", "name": "莱索托", "code": "+266" },
  { "id": "LR", "name": "利比里亚", "code": "+231" },
  { "id": "LY", "name": "利比亚", "code": "+218" },
  { "id": "LI", "name": "列支敦士登", "code": "+423" },
  { "id": "LT", "name": "立陶宛", "code": "+370" },
  { "id": "LU", "name": "卢森堡", "code": "+352" },
  { "id": "MK", "name": "北马其顿", "code": "+389" },
  { "id": "MG", "name": "马达加斯加", "code": "+261" },
  { "id": "MW", "name": "马拉维", "code": "+265" },
  { "id": "MY", "name": "马来西亚", "code": "+60" },
  { "id": "MV", "name": "马尔代夫", "code": "+960" },
  { "id": "ML", "name": "马里", "code": "+223" },
  { "id": "MT", "name": "马耳他", "code": "+356" },
  { "id": "MH", "name": "马绍尔群岛", "code": "+692" },
  { "id": "MQ", "name": "马提尼克", "code": "+596" },
  { "id": "MR", "name": "毛里塔尼亚", "code": "+222" },
  { "id": "MU", "name": "毛里求斯", "code": "+230" },
  { "id": "YT", "name": "马约特", "code": "+262" },
  { "id": "MX", "name": "墨西哥", "code": "+52" },
  { "id": "FM", "name": "密克罗尼西亚", "code": "+691" },
  { "id": "MD", "name": "摩尔多瓦", "code": "+373" },
  { "id": "MC", "name": "摩纳哥", "code": "+377" },
  { "id": "MN", "name": "蒙古", "code": "+976" },
  { "id": "ME", "name": "黑山", "code": "+382" },
  { "id": "MS", "name": "蒙特塞拉特", "code": "+1-664" },
  { "id": "MA", "name": "摩洛哥", "code": "+212" },
  { "id": "MZ", "name": "莫桑比克", "code": "+258" },
  { "id": "MM", "name": "缅甸", "code": "+95" },
  { "id": "NA", "name": "纳米比亚", "code": "+264" },
  { "id": "NR", "name": "瑙鲁", "code": "+674" },
  { "id": "NP", "name": "尼泊尔", "code": "+977" },
  { "id": "NL", "name": "荷兰", "code": "+31" },
  { "id": "AN", "name": "荷属安的列斯", "code": "+599" },
  { "id": "NC", "name": "新喀里多尼亚", "code": "+687" },
  { "id": "NZ", "name": "新西兰", "code": "+64" },
  { "id": "NI", "name": "尼加拉瓜", "code": "+505" },
  { "id": "NE", "name": "尼日尔", "code": "+227" },
  { "id": "NG", "name": "尼日利亚", "code": "+234" },
  { "id": "NU", "name": "纽埃", "code": "+683" },
  { "id": "NF", "name": "诺福克岛", "code": "+672" },
  { "id": "MP", "name": "北马里亚纳群岛", "code": "+1-670" },
  { "id": "NO", "name": "挪威", "code": "+47" },
  { "id": "OM", "name": "阿曼", "code": "+968" },
  { "id": "PK", "name": "巴基斯坦", "code": "+92" },
  { "id": "PW", "name": "帕劳", "code": "+680" },
  { "id": "PS", "name": "巴勒斯坦", "code": "+970" },
  { "id": "PA", "name": "巴拿马", "code": "+507" },
  { "id": "PG", "name": "巴布亚新几内亚", "code": "+675" },
  { "id": "PY", "name": "巴拉圭", "code": "+595" },
  { "id": "PE", "name": "秘鲁", "code": "+51" },
  { "id": "PH", "name": "菲律宾", "code": "+63" },
  { "id": "PN", "name": "皮特凯恩群岛", "code": "+870" },
  { "id": "PL", "name": "波兰", "code": "+48" },
  { "id": "PT", "name": "葡萄牙", "code": "+351" },
  { "id": "PR", "name": "波多黎各", "code": "+1-787" },
  { "id": "QA", "name": "卡塔尔", "code": "+974" },
  { "id": "RE", "name": "留尼汪", "code": "+262" },
  { "id": "RO", "name": "罗马尼亚", "code": "+40" },
  { "id": "RU", "name": "俄罗斯", "code": "+7" },
  { "id": "RW", "name": "卢旺达", "code": "+250" },
  { "id": "BL", "name": "圣巴泰勒米", "code": "+590" },
  { "id": "SH", "name": "圣赫勒拿", "code": "+290" },
  { "id": "KN", "name": "圣基茨和尼维斯", "code": "+1-869" },
  { "id": "LC", "name": "圣卢西亚", "code": "+1-758" },
  { "id": "MF", "name": "法属圣马丁", "code": "+590" },
  { "id": "PM", "name": "圣皮埃尔和密克隆", "code": "+508" },
  { "id": "VC", "name": "圣文森特和格林纳丁斯", "code": "+1-784" },
  { "id": "WS", "name": "萨摩亚", "code": "+685" },
  { "id": "SM", "name": "圣马力诺", "code": "+378" },
  { "id": "ST", "name": "圣多美和普林西比", "code": "+239" },
  { "id": "SA", "name": "沙特阿拉伯", "code": "+966" },
  { "id": "SN", "name": "塞内加尔", "code": "+221" },
  { "id": "RS", "name": "塞尔维亚", "code": "+381" },
  { "id": "SC", "name": "塞舌尔", "code": "+248" },
  { "id": "SL", "name": "塞拉利昂", "code": "+232" },
  { "id": "SG", "name": "新加坡", "code": "+65" },
  { "id": "SX", "name": "荷属圣马丁", "code": "+1-721" },
  { "id": "SK", "name": "斯洛伐克", "code": "+421" },
  { "id": "SI", "name": "斯洛文尼亚", "code": "+386" },
  { "id": "SB", "name": "所罗门群岛", "code": "+677" },
  { "id": "SO", "name": "索马里", "code": "+252" },
  { "id": "ZA", "name": "南非", "code": "+27" },
  { "id": "GS", "name": "南乔治亚岛和南桑威奇群岛", "code": "+500" },
  { "id": "SS", "name": "南苏丹", "code": "+211" },
  { "id": "ES", "name": "西班牙", "code": "+34" },
  { "id": "LK", "name": "斯里兰卡", "code": "+94" },
  { "id": "SD", "name": "苏丹", "code": "+249" },
  { "id": "SR", "name": "苏里南", "code": "+597" },
  { "id": "SJ", "name": "斯瓦尔巴特群岛和扬马延", "code": "+47" },
  { "id": "SZ", "name": "斯威士兰", "code": "+268" },
  { "id": "SE", "name": "瑞典", "code": "+46" },
  { "id": "CH", "name": "瑞士", "code": "+41" },
  { "id": "SY", "name": "叙利亚", "code": "+963" },
  { "id": "TJ", "name": "塔吉克斯坦", "code": "+992" },
  { "id": "TZ", "name": "坦桑尼亚", "code": "+255" },
  { "id": "TH", "name": "泰国", "code": "+66" },
  { "id": "TL", "name": "东帝汶", "code": "+670" },
  { "id": "TG", "name": "多哥", "code": "+228" },
  { "id": "TK", "name": "托克劳", "code": "+690" },
  { "id": "TO", "name": "汤加", "code": "+676" },
  { "id": "TT", "name": "特立尼达和多巴哥", "code": "+1-868" },
  { "id": "TN", "name": "突尼斯", "code": "+216" },
  { "id": "TR", "name": "土耳其", "code": "+90" },
  { "id": "TM", "name": "土库曼斯坦", "code": "+993" },
  { "id": "TC", "name": "特克斯和凯科斯群岛", "code": "+1-649" },
  { "id": "TV", "name": "图瓦卢", "code": "+688" },
  { "id": "UG", "name": "乌干达", "code": "+256" },
  { "id": "UA", "name": "乌克兰", "code": "+380" },
  { "id": "AE", "name": "阿拉伯联合酋长国", "code": "+971" },
  { "id": "UY", "name": "乌拉圭", "code": "+598" },
  { "id": "UZ", "name": "乌兹别克斯坦", "code": "+998" },
  { "id": "VU", "name": "瓦努阿图", "code": "+678" },
  { "id": "VE", "name": "委内瑞拉", "code": "+58" },
  { "id": "VN", "name": "越南", "code": "+84" },
  { "id": "VG", "name": "英属维尔京群岛", "code": "+1-284" },
  { "id": "VI", "name": "美属维尔京群岛", "code": "+1-340" },
  { "id": "WF", "name": "瓦利斯和富图纳", "code": "+681" },
  { "id": "EH", "name": "西撒哈拉", "code": "+212" },
  { "id": "YE", "name": "也门", "code": "+967" },
  { "id": "ZM", "name": "赞比亚", "code": "+260" },
  { "id": "ZW", "name": "津巴布韦", "code": "+263" }
];

module.exports = {
  CountryAndCode,
};