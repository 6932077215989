import React, {Component} from 'react';
import {Select, message, Icon} from 'antd';
import styles from "./AreaSelect.less";
import HttpTool from "../../tool/HttpTool";
import { CloseCircleFilled } from "@ant-design/icons";

const Option = Select.Option;

/**
 * 区域联选
 */
class AreaSelect extends Component {
    constructor(props) {
        super(props);

        //获取默认值
        this.initData = this.props.defaultValue;
        let countryValue = this.initData.countryId ? {
            id: this.initData.countryId,
            name: this.initData.country
        } : undefined;
        let provinceValue = countryValue && (this.initData.provinceId ? {
            id: this.initData.provinceId,
            name: this.initData.province
        } : undefined);
        let cityValue = countryValue && provinceValue && (this.initData.cityId ? {
            id: this.initData.cityId,
            name: this.initData.city
        } : undefined);

        this.state = {
            countrySel: true,        //国家选框是否可选
            provinceSel: provinceValue ? true : false,      //省/区
            citySel: cityValue ? true : false,          //城市

            countryData: [],        //国家下拉数据
            provinceData: [],
            cityData: [],

            //已经选择的数据
            countryValue: countryValue,
            provinceValue: provinceValue,
            cityValue: cityValue,

            upDate: 0,
            lv: parseInt(this.props.lv),      //层级

            countryDisable: this.props.countryDisable,   //设置某一栏目禁用
            provinceDisable: this.props.provinceDisable,
            cityDisable: this.props.cityDisable,
        }

        //记录每次编辑的数据
        this.param = {
            countryId: this.initData.countryId ? this.initData.countryId : undefined,
            country: this.initData.country ? this.initData.country : undefined,
            provinceId: this.initData.provinceId ? this.initData.provinceId : undefined,
            province: this.initData.province ? this.initData.province : undefined,
            cityId: this.initData.cityId ? this.initData.cityId : undefined,
            city: this.initData.city ? this.initData.city : undefined,
        };

        //默认排序和字段名
        this.itemList = ['country', 'province', 'city'];

        this.configList = [
            {
                typeName: 'country',
                placeholder: '第一级区域',
            },
            {
                typeName: 'province',
                placeholder: '第二级区域',
            },
            {
                typeName: 'city',
                placeholder: '第三级区域',
            },
        ]
    }

    componentDidMount() {
        this.getSelectOptionData("country")

        //如果有默认值，是编辑，则加载所有下拉
        this.initData.countryId && this.getSelectOptionData('province');
        this.initData.provinceId && this.getSelectOptionData('city');
    }

    //改变某个选框的可选状态
    changeSelectState(type, state) {
        this.setState({
            [type + 'Sel']: state || false,
        })
    }

    //改变某个选框的值
    changeValue(type, obj, cb) {
        let index = this.itemList.indexOf(type);
        let nearType = this.itemList[index + 1];
        let middleType = this.itemList[index + 2];

        this.setState({
            [type + 'Value']: obj || undefined,
            [nearType + 'Value']: undefined,
            [middleType + 'Value']: undefined,

            [nearType + 'Sel']: true,
            [middleType + 'Sel']: false,
        }, () => {
            this.valueChangeCB();
            cb && cb()
        })
    }

    //值改变的回调，参数为选择的数据 obj
    valueChangeCB() {
        let {countryValue, provinceValue, cityValue} = this.state;
        this.param = {
            countryId: countryValue ? countryValue.id : undefined,
            country: countryValue ? countryValue.name : undefined,
            provinceId: provinceValue ? provinceValue.id : undefined,
            province: provinceValue ? provinceValue.name : undefined,
            cityId: cityValue ? cityValue.id : undefined,
            city: cityValue ? cityValue.name : undefined,
        };

        this.props.onChange && this.props.onChange(this.param);
    }

    //验证输入
    verValue(config) {
        if (!config || !config.required) {
            //如果是非必填的，直接返回true,验证通过
            return true;
        }
        let {countryValue, provinceValue, cityValue, lv} = this.state;

        let country = countryValue ? true : false;
        let province = provinceValue ? true : false;
        let city = cityValue ? true : false;

        let state = false;
        switch (lv) {
            case 1:
                state = country;
                break;
            case 2:
                state = country && province;
                break;
            case 3:
                state = country && province && city;
                break;
            default:
                break;
        }

        return state;
    }

    //配置某个选框的下拉数据
    initSelectOptions(type, data) {
        this.setState({
            [type + 'Data']: data || [],
            [type + 'Sel']: true,
        })
    }

    /**
     *  请求数据
     */
    getSelectOptionData(type) {
        if (!type) {
            return;
        }

        let {countryValue, provinceValue} = this.state;

        switch (type) {
            case "country":
                HttpTool.post("/admin/common/getCountryList", (code, msg, json) => {
                    let c = [];
                    json.forEach((countryItem) => {
                        if (countryItem._id) {
                            c.push({
                                name: countryItem.name,
                                id: countryItem._id,
                                isLeaf: false,
                                type: 1
                            })
                        }
                    })
                    this.setState({
                        countryData: c
                    })
                }, (code, msg) => {
                    message.error(msg);
                }, {});
                break;
            case "province":
                HttpTool.post("/admin/common/getProvinceList", (code, msg, json) => {
                    let c = [];
                    json.forEach((provinceItem) => {
                        c.push({
                            name: provinceItem.name,
                            id: provinceItem._id,
                            isLeaf: false,
                            type: 2
                        })
                    })
                    this.setState({
                        provinceData: c
                    })
                }, (code, msg) => {
                    message.error(msg);
                }, {
                    countryId: countryValue.id,
                });
                break;
            case "city":
                HttpTool.post("/admin/common/getCityList", (code, msg, json) => {
                    let c = [];
                    json.forEach((cityItem) => {
                        c.push({
                            name: cityItem.name,
                            id: cityItem._id,
                            isLeaf: true,
                            type: 3
                        })
                    })
                    this.setState({
                        cityData: c
                    })
                }, (code, msg) => {
                    message.error(msg);
                }, {
                    countryId: countryValue.id,
                    provinceId: provinceValue.id,
                });
                break;
        }
    }


    //渲染下拉选项
    getOptions(items) {
        let view = [];
        for (let key in items) {
            view.push(
                <Option
                    key={'key' + key}
                    value={items[key].id}
                >
                    {items[key].name}
                </Option>
            );
        }

        return view;
    }

    render() {
        return (
            <div className={styles.container}>
                {this.getItem(this.configList[0])}
                <span>-</span>
                {this.getItem(this.configList[1])}
                <span>-</span>
                {this.getItem(this.configList[2])}
                &nbsp;&nbsp;
                <CloseCircleFilled
                    onClick={()=>{
                        this.setState({
                            countryValue: null,
                            provinceValue: null,
                            cityValue: null,
                            countrySel: true,        //国家选框是否可选
                            provinceSel: false,      //省/区
                            citySel: false,          //城市
                        },()=>{
                            this.valueChangeCB();
                        })
                    }}
                />
            </div>
        );
    }

    /**
     * 解析配置返回选框
     * @param config
     */
    getItem(config) {
        let typeName = config.typeName;
        let index = this.itemList.indexOf(typeName);
        let nextTypeName = this.itemList[index + 1];

        return (
            <Select
                value={this.state[typeName + 'Value'] ? this.state[typeName + 'Value'].id : undefined}
                disabled={this.props.disabled || !this.state[typeName + 'Sel']}
                showSearch={true}
                style={{minWidth: 60, width: "28%"}}
                placeholder={config.placeholder}
                optionFilterProp="children"
                onSelect={(value, obj) => {
                    if (this.state[typeName + 'Value'] && value == this.state[typeName + 'Value'].id) {
                        return;
                    }
                    let item = {
                        id: value,
                        name: obj.props.children,
                    };
                    this.changeValue(typeName, item, () => {
                        this.getSelectOptionData(nextTypeName);
                    });
                }}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
                {this.getOptions(this.state[typeName + 'Data'])}
            </Select>
        );
    }
}

AreaSelect.defaultProps = {
    lv: 3,
    disabled: false,
    countryDisable: false,
    provinceDisable: false,
    cityDisable: false,
    defaultValue: {},
};

export default AreaSelect;