import React from 'react';
import styles from './DeviceShare.less';
import {Spin, Button, message, Row, Col, Modal, Tree} from 'antd';
import APILXD from '../../../http/APILXD.js';
import HttpTool from "../../../tool/HttpTool";
import Layout from "../../../components/layout/index";

class DeviceShare extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            upDate: 0,
        }
    }

    componentDidMount() {

    }

    render() {
        return (
            <div className={styles.mainPage}>
                <Spin size={'large'} spinning={this.state.loading}>
                    <div className={styles.formContainer}>
                        <Layout
                            key={'layout' + this.state.upDate}
                            ref={(ref) => {
                                this.baseMsgLayout = ref;
                            }}
                            {
                                ...this.getConfig()
                            }
                        />

                        <Row>
                            <Col span={24} offset={4}>
                                <Button
                                    size={'large'}
                                    className={styles.submitBtn}
                                    type={'primary'}
                                    onClick={() => {
                                        this.clickAddBtn();
                                    }}
                                >{"确定"}</Button>
                                <Button
                                    size={'large'}
                                    className={styles.cancelBtn}
                                    onClick={() => {
                                        this.props.openTab(this.props.post.parent);
                                        this.props.closeTab(this.props.targetKey);
                                    }}
                                >返回</Button>
                            </Col>
                        </Row>
                    </div>
                </Spin>
            </div>)
    }

    //点击创建完成按钮
    clickAddBtn() {
        let option = this.baseMsgLayout.getLayoutValue(true);
        if (option.error) {
            message.warning('请完善表单信息');
            return;
        }

        let params = {
            companyId: option.parameter.companyId,
            deviceIds: _.map(option.parameter.deviceList.split(/[,\n]/g), _.trim).filter((data) => {
                return !!data
            })
        };
        this.doSubmit(params);
    }

    //提交数据
    doSubmit(data) {
        let param = data;
        log(data);
        let successCB = (code, msg, json, option) => {
            this.setState({loading: false}, () => {
                message.success('操作成功');
                this.props.openTab(this.props.post.parent);
                this.props.closeTab(this.props.targetKey);
            });
        };
        let failureCB = (code, msg) => {
            this.setState({loading: false}, () => {
                message.error(msg);
            });
        };

        this.setState({loading: true});
        let url = APILXD.assignDeviceListToSpace;
        HttpTool.post(url, successCB, failureCB, param);
    }

    //配置
    getConfig(data) {
        let defaultOption = {
            type: "input",
            ver: true,
            reg: /^[\s\S][^，]{1,300000}$/,
            verMessage: "请输入正确的数据，注意不要有中文逗号,空格会自动清除",
            required: true,
        };
        return {
            colCount: 1,
            formItemLayout: {
                labelCol: {span: 4},
                wrapperCol: {span: 20, offset: 0},
            },
            parameterArr: [
                {
                    ver: true,
                    verMessage: "请选择",
                    required: true,

                    field: "companyId",
                    name: "公司名称",
                    type: 'select',
                    selectType: 'value',
                    reg: (v) => {
                        return !!v;
                    },
                    apiConfig: {
                        url: APILXD.getAllSpaces,
                        fillObject: (json) => {
                            let data = [];
                            for (let obj of json) {
                                data.push({
                                    title: obj.name,
                                    value: obj._id,
                                });
                            }
                            return data;
                        }
                    },
                    option: {
                        dropdownMatchSelectWidth: false,
                        showSearch: true,
                        onSearch: () => {},
                        filterOption: (input, option) => {
                            return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        },
                        optionFilterProp: "children",
                    },
                    data: []
                },
                {
                    ...defaultOption,
                    field: "deviceList",
                    name: "设备编号",
                    type: "TextArea",
                    option: {
                        placeholder: '请输入设备编码，英文逗号(,)分隔，例如 2131231,sadasdsa 注：空格会自动清除',
                        autosize: {
                            minRows: 15,
                            maxRows: 15,
                        },
                        maxLength: '300000',
                        defaultValue: this.props.post.deviceList ? this.props.post.deviceList.toString() : ""
                    }
                },
            ],
        }
    }
}

module.exports = DeviceShare;