import React from 'react';
import styles from './DeviceShare.less';
import {Spin, Button, message, Row, Col, Modal, Tree, Input} from 'antd';
import APILXD from '../../../http/APILXD.js';
import HttpTool from "../../../tool/HttpTool";
import Layout from "../../../components/layout/index";

const TextArea = Input.TextArea;
const vm = require("vm");

class DeviceShareRules extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            upDate: 0,
            codeBlock: "",
            deviceId: "",
            romId: "",
            result: ""
        }

        this.powerConfig = window.___.getPower('DeviceManage');
    }

    componentDidMount() {
        this.setState({
            loading: true
        }, () => {
            this.getCode();
        });

    }

    getCode() {
        let successCB = (code, msg, json, option) => {
            this.setState({loading: false}, () => {
                this.setState({
                    codeBlock: json
                })
            });
        };
        let failureCB = (code, msg) => {
            this.setState({loading: false}, () => {
                message.error(msg);
            });
        };
        let url = APILXD.getDeviceDynamicAssignConf;
        HttpTool.post(url, successCB, failureCB, {});
    }

    render() {
        return (
            <div className={styles.mainPage}>
                <Spin size={'large'} spinning={this.state.loading}>
                    <div className={styles.formContainer}>
                        <Layout
                            key={'layout' + this.state.upDate}
                            ref={(ref) => {
                                this.baseMsgLayout = ref;
                            }}

                        />

                        <Row>
                            <Col span={24}>
                                <div className={styles.inputTitle}>根据条件，新增长的设备自动分配到指定公司</div>
                                <div className={styles.inputTitle2}>默认参数:deviceId(设备号) romId(系统)</div>
                                <div className={styles.inputTitle2}>返回值:公司编码 (请到 账号管理 => 公司管理查看公司编码)</div>
                                <div
                                    className={styles.inputTitle2}>{"示例:if(deviceId === \"device1\" || romId === \"rom1\"){return \"gongsi1\"}else{return null}"}</div>
                                <TextArea
                                    rows={20}
                                    readOnly={!this.powerConfig.Edit}
                                    size={"large"}
                                    onChange={(data) => {
                                        this.setState({
                                            codeBlock: data.target.value
                                        })
                                    }
                                    }
                                    value={this.state.codeBlock}
                                    placeholder="根据条件，新增长的设备自动分配到指定公司"
                                    autoSize={{minRows: 20, maxRows: 20}}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col span={2} offset={22}>
                                <Button
                                    disabled={!this.powerConfig.Edit}
                                    size={'large'}
                                    style={{marginTop: 10, width: "100%"}}
                                    type={'primary'}
                                    onClick={() => {
                                        this.doSubmit();
                                    }}
                                >{"保存"}</Button>
                            </Col>
                        </Row>

                        <div className={styles.inputTitle}>{"测试代码执行情况:"}</div>
                        <Row gutter={16} style={{marginTop: 10}} type="flex" align="middle">
                            <Col span={1.5}>
                                <div>deviceId:</div>
                            </Col>
                            <Col span={10}>
                                <Input
                                    styles={{marginTop: 10}}
                                    onChange={(data) => {
                                        this.setState({
                                            deviceId: data.target.value
                                        })
                                    }}
                                    placeholder="请输入设备号"
                                />
                            </Col>
                            <Col span={1.5}>
                                <div>romId:</div>
                            </Col>
                            <Col span={10}>
                                <Input
                                    styles={{marginTop: 10}}
                                    onChange={(data) => {
                                        this.setState({
                                            romId: data.target.value
                                        })
                                    }}
                                    placeholder="请输入RomId"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={3} offset={18}>
                                <Button
                                    size={'large'}
                                    style={{marginTop: 10, width: "100%"}}
                                    type={'primary'}
                                    danger={true}
                                    ghost={true}
                                    onClick={() => {
                                        this.setState({
                                            result: ""
                                        })
                                    }}
                                >{"清空测试结果"}</Button>
                            </Col>

                            <Col span={2} offset={1}>
                                <Button
                                    size={'large'}
                                    style={{marginTop: 10, width: "100%"}}
                                    type={'primary'}
                                    onClick={() => {
                                        this.btnTest();
                                    }}
                                >{"测试"}</Button>
                            </Col>

                            <Col span={24}>
                                <div className={styles.inputTitle}>测试结果</div>
                                <div>{this.state.result}</div>
                            </Col>
                        </Row>
                    </div>
                </Spin>
            </div>)
    }

    btnTest() {
        try {
            let context = {
                deviceId: this.state.deviceId,
                romId: this.state.romId,
            };
            let {codeBlock} = this.state;
            //const script = new vm.Script(codeBlock);
            const script = new vm.Script(`result = function(){${codeBlock}}()`);
            script.runInNewContext(context);
            this.setState({
                result: context.result + ""
            })
        } catch (e) {
            this.setState({
                result: "出错:" + e
            })
        }

    }

    //提交数据
    doSubmit() {
        let param = {
            codeBlock: this.state.codeBlock
        };

        let successCB = (code, msg, json, option) => {
            this.setState({loading: false}, () => {
                message.success('操作成功');
            });
        };
        let failureCB = (code, msg) => {
            this.setState({loading: false}, () => {
                message.error(msg);
            });
        };

        this.setState({loading: true});
        let url = APILXD.setDeviceDynamicAssignConf;
        HttpTool.post(url, successCB, failureCB, param);
    }
}

module.exports = DeviceShareRules;