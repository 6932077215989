import React from 'react';
import less from './SystemSet.less';
import {Spin, Button, message, Modal} from 'antd';
import InputWidthText from '../../../components/inputWidthText/InputWidthText';
import APILXD from '../../../http/APILXD.js';
import HttpTool from "../../../tool/HttpTool";
import Layout from '../../../components/layout/index.js';

class SystemSet extends React.Component {
    constructor(props) {
        super(props);

        this.id = this.props.post.id;
        this.state = {
            loading: false,
            defaultData: {},
            upDate: 0,
        };
        this.powerConfig = window.___.getPower('SystemSet');
    }

    componentDidMount() {
        this.loadData();
    }

    render() {
        let data = this.state.defaultData;
        return (<div className={less.mainPage}>
            <Spin size={'large'} spinning={this.state.loading}>
                <div className={less.containerTitle}>设备全局配置</div>
                <Layout
                    key={'layout' + this.state.upDate}
                    ref={(ref) => {
                        this.baseMsgLayout = ref;
                    }}
                    {
                        ...this.getConfig(data)
                    }
                />
                <div className={less.btnBox}>
                    <Button
                        size={'large'}
                        className={less.cancelBtn}
                        onClick={() => {
                            this.props.closeTab(this.props.targetKey);
                        }}
                    >返回</Button>
                    {
                        this.powerConfig.Edit
                        ?    <Button
                                size={'large'}
                                className={less.submitBtn}
                                type={'primary'}
                                onClick={() => {
                                    Modal.confirm({
                                        title: '提示',
                                        content: '是否确认此次编辑？',
                                        onOk: () => {
                                            this.clickAddBtn();
                                        }
                                    });
                                }}
                            >确认编辑</Button>
                        :null
                    }
                </div>
            </Spin>
        </div>)
    }

    //点击创建完成按钮
    clickAddBtn() {
        let option = this.baseMsgLayout.getLayoutValue(true);
        if (option.error) {
            message.warning('请完善表单信息');
            return;
        }

        let param = option.parameter;

        this.doSubmit(param);
    }

    //提交数据
    doSubmit(data) {
        let param = data;
        log(data)
        let successCB = (code, msg, json, option) => {
            this.setState({loading: false}, () => {
                message.success(msg);
                this.loadData()
            });
        };
        let failureCB = (code, msg) => {
            this.setState({loading: false}, () => {
                message.error(msg);
            });
        };

        this.setState({loading: true});
        HttpTool.post(APILXD.setGlobalConfigs, successCB, failureCB, param);
    }

    //请求数据
    loadData() {
        let param = {};
        let successCB = (code, msg, json, option) => {
            this.setState({
                loading: false,
                defaultData: json,
                upDate: this.state.upDate + 1,
            });
        };
        let failureCB = (code, msg) => {
            this.setState({loading: false}, () => {
                message.error(msg);
            });
        };

        this.setState({loading: true});
        HttpTool.post(APILXD.getAllGlobalConfigs, successCB, failureCB, param);
    }

    //配置
    getConfig(data) {
        let couldEdit = !this.powerConfig.Edit;
        let logData = data && data.log && JSON.parse(data.log) || null;
        log(logData);
        let defaultOption = {
            type: "input",
            ver: true,
            reg: /^[a-zA-Z0-9]{6,12}$/,
            verMessage: "请输入",
            required: true,
        };
        return {
            colCount: 1,
            formItemLayout: {
                labelCol: {span: 6},
                wrapperCol: {span: 10, offset: 0},
            },
            parameterArr: [
                {
                    ...defaultOption,
                    field: "monthOnlineHour",
                    name: "设备每月达标小时数",
                    type: 'diy',
                    component: InputWidthText,
                    behindText: '小时',
                    verMessage: "请输入，仅支持数字",
                    reg: (v) => {
                        return v > 0
                    },
                    option: {
                        style: {width: 120},
                        placeholder: ' ',
                        defaultValue: data && data.monthOnlineHour || undefined,
                        max: 100000000,
                        min: 1,
                        precision: 2,
                        disabled:couldEdit
                    }
                },
                {
                    ...defaultOption,
                    field: "ysb",
                    name: "压缩包",
                    purpose: "see",
                    reg: (v) => {
                        return true
                    },
                    option: {
                        defaultValue: "eba585730ecbf598"
                    }
                },
            ],
        }
    }
}

module.exports = SystemSet;