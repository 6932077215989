import React from 'react';
import ListPage from '../../base/ListPage.js';
import less from './LogManage.less';
import APILXD from '../../../http/APILXD.js';
import TimeHelp from '../../../tool/TimeHelp';
import {Popover,message,Button} from 'antd';
import moment from 'moment';
import HttpTool from "../../../tool/HttpTool";
import ModalConfig from "../../../components/modalConfig/index";

class SystemLogList extends ListPage {
    constructor(props) {
        super(props);
    }

    base_getListConfig() {
        return {
            table: {
                columns: this.getTableColumns(),
                url: APILXD.getSyslogList,
                otherParam: null,
            },
            search: {
                seniorSearch: this.getSeniorSearch(),
                option: {
                    openSenior: true,
                    hideSeniorBtn: true,
                    placeholder: '请输入错误内容进行搜索',
                    reg: /^[\s\S]*$/,
                    beforeSubmit: (value) => {
                        return true;
                    }
                }
            },
            toolBar: ()=>{
                return <div>
                    {this.powerConfig.Delete?<Button
                        type={"primary"}
                        danger={true}
                        onClick={()=>{
                            this.closeLog(this.searchParam)
                        }}
                    >删除当前搜索条件下的错误日志</Button>:null}
                </div>
            }
        }
    }

    getSeniorSearch() {
        let defaultOption = {
            type: "input",
            ver: true,
            reg: /^[\S\s]{0,50}$/,
            verMessage: "最多50个字符",
            required: false,
        };
        return {
            colCount: 3,
            formItemLayout: {
                labelCol: {span: 8},
                wrapperCol: {span: 16, offset: 0},
            },
            parameterArr: [
                {
                    ...defaultOption,
                    field: "type",
                    name: "类型",
                    type: 'input',
                    reg: (v) => {
                        return true;
                    },
                    option:{
                        placeholder:"请输入类型"
                    }
                },
                {
                    ...defaultOption,
                    field: "deviceId",
                    name: "设备id",
                    type: 'input',
                    reg: (v) => {
                        return true;
                    },
                    option:{
                    }
                }
            ],
        }
    }

    /**
     *  列表数据配置
     */
    getTableColumns() {
        return [
            {
                title: '系统日志id',
                dataIndex: '_id',
                key: '_id',
            },
            {
                title: '设备id',
                dataIndex: 'deviceId',
                key: 'deviceId',
            },
            {
                title: '类型',
                dataIndex: 'type',
                key: 'type',
            },
            {
                title: '应用版本',
                dataIndex: 'center',
                key: 'center',
                render: (text, record)=>{
                    return <div>
                        <div>center: {record.center}</div>
                        <div>remote: {record.remote}</div>
                        <div>script: {record.script}</div>
                    </div>
                }
            },
            {
                title: '安卓可视版本',
                dataIndex: 'model',
                key: 'model',
            },
            {
                title: '系统版本号',
                dataIndex: 'sysversion',
                key: 'sysversion',
            },
            {
                title: '包名',
                dataIndex: 'packagename',
                key: 'packagename',
            },
            {
                title: '错误内容',
                dataIndex: 'content',
                key: 'content',
                render:(text)=>{
                    if(!text){
                        return "-"
                    }
                    return (<div>
                        <span>{text.slice(0,50)}...</span>
                        <span
                            className={less.btnDetail}
                            onClick={()=>{
                                ModalConfig.show({
                                        maskClosable: true,
                                        width: "60%",
                                        footer: null,
                                    }
                                    , <div className={less.contentBox}>{text}</div>)
                            }}
                        >查看</span>
                    </div>)
                }
            },
            {
                title: '创建时间',
                dataIndex: 'addtime',
                key: 'addtime',
                render:(txt)=>{
                    return <Popover content={moment(txt).fromNow()}>{TimeHelp.getYMDHM(txt)}</Popover>;
                }
            },
        ]
    }

    closeLog(param){

        let successCB = (code, msg, json, option) => {
            this.setState({
                loading: false,
            },()=>{
                message.success(msg);
                this._loadDataForNet();
            });
        };
        let failureCB = (code, msg) => {
            this.setState({loading: false}, () => {
                message.error(msg);
            });
        };

        this.setState({loading: true}, () => {
            HttpTool.post(APILXD.delSyslog, successCB, failureCB, param);
        });
    }
}

module.exports = SystemLogList;