import React, {Component} from 'react';
import {Radio} from 'antd';

let RadioGroup = Radio.Group;

/**
 * 单选模板
 */
class Index extends Component {
    constructor(props) {
        super(props);
    }

    date = (e) => {
        this.props.data.resultValue = this.formatValueType(e.target.valueType, e.target.value);

        this.props.verification(this.props.data, this.props.data.resultValue)
    }

    getValueTypeObject(value) {
        if(value === null){
            return {
                type: "null",
                value: "null"
            }
        }
        if(value === undefined){
            return {
                type: "undefined",
                value: "undefined"
            }
        }

        let type = typeof (value);

        return {
            type: type,
            value: type === "object" ? JSON.stringify(value) : value.toString()
        }
    }

    formatValueType(type, value) {
        // console.log("看看返回的是个啥")
        // console.log(type)
        // console.log(value)
        switch (type) {
            case "boolean":
                return value === "true";
            case "number":
                return new Number(value).valueOf();
            case "string":
                return new String(value).valueOf();
            case "object":
                return JSON.parse(value);
            case "undefined":
                return undefined;
            case "null":
                return null;
            default:
                return value;
        }
    }

    render() {
        let {data} = this.props;
        if(this.props.option.defaultValue || this.props.option.defaultValue === 0){
            this.props.option.defaultValue = String(this.props.option.defaultValue)
        }
        return (
          <div>
              {(data.purpose == "edit" || !data.purpose) ?
                <RadioGroup
                  {...this.props.option}
                  onChange={this.date}
                >
                    {data.data ? data.data.map((obj, key) => {
                        obj.temp = this.getValueTypeObject(obj.value)
                        return <Radio value={obj.temp.value} valueType={obj.temp.type} key={key}>{obj.title}</Radio>
                    }) : null}
                </RadioGroup> : <div>{this.sortOut()}</div>
              }

          </div>
        )
    }

    sortOut = () => {
        let {data} = this.props;
        let str = '';
        if (data.option && data.option.defaultValue) {
            //此处，在展示详情的时候因该直接显示title的值即可，不做判断
            if (data.option.defaultValue.title) {
                str = data.option.defaultValue.title;
            } else if (data.data) {
                let matchKey = data.option.defaultValue;
                for(let i = 0;i<data.data.length;i++){
                    if(String(data.data[i].value) === String(matchKey)){
                        str = data.data[i].title;
                    }
                }
            }
        }
        return str
    }

}


export default Index;