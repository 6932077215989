import React from "react";
import APILXD from "../../../../http/APILXD";
import * as echarts from "echarts";
import TimeHelp from "../../../../tool/TimeHelp";
import {message} from "antd";
import styles from "../DeviceStaticsChart.less";
import HttpTool from "../../../../tool/HttpTool";

export default class HisAddView extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            boxMsg: null
        }
        this.option = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
            },
            grid: {
                containLabel: true,
                left: "20px",
                right: "20px",
                top: "40px",
                bottom: "0px"
            },
            xAxis: {
                name: "日期",
                type: 'category',
                axisLabel: {
                    rotate: -45,
                },
                nameTextStyle: {
                    fontSize: 0
                },
                data: []
            },
            yAxis: {
                name: "新增数（台）",
                type: 'value',
                minInterval: 1,
            },
            series: [
                {
                    name: '新增数',
                    type: 'line',
                    lineStyle: {
                        color: "limegreen"
                    },
                    itemStyle: {
                        color: "limegreen"
                    },
                    data: []
                },
            ]
        }
    }

    componentDidMount(){
        setTimeout(()=>{
            this.boxChart = echarts.init(this.boxDom);
            this.getLatestAllAddDevice()
        },0)
    }

    getLatestAllAddDevice(){
        this.setState({
            loading: true
        },()=>{
            HttpTool.post(APILXD.getLatestAllAddDevice,(code,msg,json)=>{
                this.setState({
                    loading: false
                },()=>{
                    this.renderBoxChart(json)
                })
            },(code,msg)=>{
                message.error(msg);
                this.setState({
                    loading: false
                })
            },{
                romIds: this.props.selRomIds.length?this.props.selRomIds:null,
            })
        })
    }


    renderBoxChart(data){
        let option = _.cloneDeep(this.option);

        let xData = [];
        let vData = [];

        let total = 0;

        data.forEach((item)=>{
            vData.push({
                value: item.total,
            });
            xData.push(TimeHelp.getYMDFormat(item.time,"MM-dd"));
            total+=item.total;
        })

        option.series[0].data = vData;
        option.xAxis.data = xData;

        this.setState({
            boxMsg: {
                total
            }
        })
        this.boxChart && this.boxChart.setOption(option)
    }

    render(){
        let {boxMsg} = this.state;
        return <div
            ref={ref=>this.boxDom = ref}
            className={styles.chartContainer}
        >
            {
                boxMsg!==null
                    ?<div className={styles.boxThreeExtra}>
                        <div>总数量：{boxMsg.total}</div>
                    </div>
                    :null
            }
        </div>
    }
}